import { FigmaLogo } from "../../../../../../assets/svg/figma_logo";
import { GoogleLogo } from "../../../../../../assets/svg/google_logo";
import { ButtonNew } from "../../../../atoms/ButtonNew";

import "./SignUpCta.css";

export const SignUpCta = () => {
  return (
    <div className="sign-in__signin-container">
      <ButtonNew
        className="sign-in__signin-button"
        iconStart={<GoogleLogo />}
        href="api/auth/google/login"
        target="_self"
      >
        Sign up with Google
      </ButtonNew>
      {/* <ButtonNew className="sign-in__signin-button" iconStart={<FacebookLogo />}>
          Sign in with Facebook
        </ButtonNew> */}
      <div className="sign-in__signin-separator">
        <div className="sign-in__signin-separator__line"></div>
        <div className="codejet__typography sign-in__separator__or">or</div>
        <div className="sign-in__signin-separator__line"></div>
      </div>
      <ButtonNew
        className="sign-in__signin-button"
        iconStart={<FigmaLogo />}
        href="api/auth/google/login"
        target="_self"
      >
        Sign up with Figma
      </ButtonNew>
      {/* <ButtonNew className="sign-in__signin-button">Sign in with your Email</ButtonNew> */}
    </div>
  );
};
