import { View } from "../modal.service.ts";

import { ModalViewDefault } from "./default.tsx";
import { DomainsModal } from "./Domains/Domains.tsx";
import { CustomDomainModal } from "./CustomDomain/CustomDomain.tsx";
import { UnpublishDomainModal } from "./UnpublishDomain/UnpublishDomain.tsx";
import { UploadAsset } from "./UploadAssets/UploadAsset.tsx";
import { UpgradeModal } from "./UpgradeModal/UpgradeModal.tsx";
import { SignUpModal } from "./SignUpModal";
import { AiNotifyModal } from "./AiNotifyModal";

import { SmartComponentModal } from "./SmartComponentModal";
import { TemplateWelcomeModal } from "./TemplateWelcomeModal";
import { AiNotifySuccessModal } from "./AiNotifySuccessModal/AiNotifySuccessModal.tsx";

export const views: View[] = [
  {
    id: "defaultView",
    component: ModalViewDefault,
  },
  {
    id: "domains",
    component: DomainsModal,
  },
  {
    id: "customDomain",
    component: CustomDomainModal,
  },
  {
    id: "unpublishDomain",
    component: UnpublishDomainModal,
  },
  {
    id: "uploadAsset",
    component: UploadAsset,
  },
  {
    id: "upgradeModal",
    component: UpgradeModal,
  },
  {
    id: "signUp",
    component: SignUpModal,
  },
  {
    id: "smart-component-modal",
    component: SmartComponentModal,
  },
  {
    id: "template-welcome-toast",
    component: TemplateWelcomeModal,
  },
  {
    id: "ai-notify-modal",
    component: AiNotifyModal,
  },
  {
    id: "ai-notify-success-toast",
    component: AiNotifySuccessModal,
  },
];
