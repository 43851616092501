import { type SVGProps } from "react";

export const AiStars = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="ico">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13916 0.281434C2.99116 -0.0938109 2.4601 -0.0938117 2.3121 0.281434L2.14251 0.711453C1.88432 1.36611 1.36611 1.88432 0.711454 2.14251L0.281434 2.3121C-0.0938109 2.4601 -0.0938117 2.99116 0.281434 3.13916L0.711453 3.30876C1.36611 3.56695 1.88432 4.08515 2.14251 4.73981L2.3121 5.16983C2.4601 5.54507 2.99116 5.54508 3.13916 5.16983L3.30876 4.73981C3.56695 4.08516 4.08515 3.56695 4.73981 3.30876L5.16983 3.13916C5.54507 2.99116 5.54508 2.4601 5.16983 2.3121L4.73981 2.14251C4.08516 1.88432 3.56695 1.36611 3.30876 0.711454L3.13916 0.281434ZM8.53183 4.24531C8.30984 3.68244 7.51324 3.68244 7.29125 4.24531L7.03685 4.89034C6.64956 5.87232 5.87225 6.64963 4.89027 7.03692L4.24524 7.29132C3.68237 7.51331 3.68237 8.30991 4.24524 8.5319L4.89027 8.78629C5.87225 9.17358 6.64956 9.95089 7.03685 10.9329L7.29125 11.5779C7.51324 12.1408 8.30984 12.1408 8.53183 11.5779L8.78622 10.9329C9.17351 9.95089 9.95082 9.17358 10.9328 8.78629L11.5778 8.5319C12.1407 8.30991 12.1407 7.51331 11.5778 7.29132L10.9328 7.03692C9.95082 6.64963 9.17351 5.87232 8.78622 4.89034L8.53183 4.24531Z"
        fill="#34343A"
      />
    </g>
  </svg>
);
