import { useRef } from "react";

import { colorPickerServiceInstance } from "../../../../../../AlphaO/atoms/ColorPicker/ColorPicker.service";

import { PropertyLabel } from "../PropertyLabel";
import { StyleManagerInput } from "../StyleManagerInput";

import { getValueFromVariable } from "../../helpers/getValueFromVariable";

import { TColorProperty } from "./ColorProperty.types";

import "./ColorProperty.css";

export const ColorProperty: TColorProperty = ({ property, allVariables }) => {
  const propertyRef = useRef<HTMLDivElement>(null);
  const canClear = property.canClear();
  const placeholder = "#000";
  const className = property.get("className");
  const parentValue = property.getParentTarget()?.getStyle()[property.getName()];
  const value = property.getValue() === "" && parentValue ? parentValue : property.getValue();
  const usedValue = value.startsWith("var(") ? getValueFromVariable(allVariables, value) : value;

  const handleChange = (string: string) => {
    property.upValue(string);
  };

  const onClickHandler = () => {
    if (!propertyRef.current) return;
    const position = propertyRef.current.getBoundingClientRect();
    colorPickerServiceInstance.setupColorPicker(
      usedValue,
      handleChange,
      {
        x: position.left,
        y: position.top,
      },
      "left"
    );
  };

  return (
    <div className={`color-property${className ? ` ${className}` : ""}`} ref={propertyRef}>
      <PropertyLabel
        text={property.getLabel()}
        modifier={canClear ? "edited" : "primary"}
        clearValue={canClear ? () => property.clear() : undefined}
      />
      <StyleManagerInput
        type="text"
        value={usedValue}
        handleChange={handleChange}
        hasUnits={false}
        placeholder={placeholder}
        color={true}
        handleColorPicker={onClickHandler}
        dataTest={["style-change", property.getName()]}
      />
    </div>
  );
};
