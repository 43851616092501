import { useEffect } from "react";
import { toast } from "react-toastify";

import { Seo } from "../../atoms/Seo";

import { SignUpCta } from "../../molecules/CreateAccount/atoms/SignUpCta";
import { Logotype } from "../../../../assets/svg/logotype";

import type { TSignUp } from "./SignUp.types";

import "../Login/Login.css";
import "./SignUp.css";

let counter = 0;

export const SignUp: TSignUp = ({ toastMsg }) => {
  useEffect(() => {
    if (toastMsg !== undefined && counter === 0) toast.info(toastMsg);
    counter++;
  }, []);
  return (
    <>
      <Seo title="Get started - Codejet" />
      <div className="login">
        <div className="login__wrapper">
          <Logotype />
          <div className="login__content">
            <div className="login__text-block">
              <h1 className="login__header">Create an account</h1>
              <p className="login__description">Continue to Codejet</p>
            </div>
            <SignUpCta />
            <div className="sign-in__login-container">
              <div className="codejet__typography sign-up__login-desc">
                By creating an account, you agree to receive Codejet emails, including marketing emails, and to our
                Terms of Service & Privacy Policy
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
