import { BlockProperties } from "grapesjs";

export const button: BlockProperties = {
  id: "Button",
  media: `<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="light-fill" d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V14C40 15.1046 39.1046 16 38 16H2C0.895431 16 0 15.1046 0 14V2Z" fill="#D4D4D8"/><path d="M6 8H34" stroke="#27272A" stroke-linecap="round"/></svg>`,
  label: "Button",
  category: "Forms",
  content: {
    type: "button",
    components: [
      {
        type: "text",
        tagName: "span",
        content: "Click me!",
        classes: ["cj-button__text"],
      },
    ],
  },
  select: true,
};
