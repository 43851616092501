import { useEffect, useState } from "react";

import "./UploadAsset.css";
import { assetManagerInstance } from "../../../asset-manager";
import { MyDropzone } from "./DropZone.tsx";
import { stylesManagerInstance } from "../../../styles-manager/index.ts";

const imageTypes = {
  "image/png": [".png"],
  "image/svg": [".svg"],
  "image/jpg": [".jpg"],
};

const videoTypes = {
  "video/mp4": [".mp4"],
};
export const UploadAsset = () => {
  const machineValueKey = Object.keys(assetManagerInstance.getSnapshot().value)[0];
  const selectedTypes = machineValueKey === "UPLOAD_VIDEO" ? videoTypes : imageTypes;

  return (
    // <div className="unpublish-modal" style={{ display: isFilePickerOpen ? "none" : "block" }}>
    // hide for now dropzone
    // <div className="unpublish-modal">
    <div className="unpublish-modal vs-hidden">
      <div className="unpublish-modal__cta-wrapper">
        <button
          className="unpublish-modal__cta unpublish-modal__cta--cancel"
          onClick={() => {
            assetManagerInstance.send("CLOSE_MODAL");
            stylesManagerInstance.send("CANCEL_BG_CHANGE");
          }}
        >
          Cancel
        </button>

        <MyDropzone acceptedTypes={selectedTypes} autoFilePickerOpen={true} />
      </div>
    </div>
  );
};
