import { useEffect, useState } from "react";

import useOutsideClick from "../../../../../hooks/useOutsideClick/useOutsideClick";

import { FigmaInfoModal as FigmaInfoModalService } from "./FigmaInfoModal.service";

import { RedWarningTriangle } from "../../../../../../assets/svg/red_warning_triangle";
import { Email } from "../../../../../../assets/svg/email";

import { ButtonNew } from "../../../../atoms/ButtonNew";

import { TFigmaInfoModal } from "./FigmaInfoModal.types";

import "./FigmaInfoModal.css";

const importModal = FigmaInfoModalService.getInstance();

export const FigmaInfoModal: TFigmaInfoModal = ({ message, icon, title, isLoading, buttons = [] }) => {
  const [_, setState] = useState(false);
  useEffect(() => {
    importModal.subscribe(setState);
    return () => importModal.unsubscribe(setState);
  }, []);
  const isOpen = importModal.state;
  const closeModal = () => importModal.closeModal();
  const ref = useOutsideClick<HTMLDivElement>(closeModal);
  const isCloseButton = () => {
    let isCloseButton = false;
    buttons.map(({ isCloseBtn }) => {
      if (isCloseBtn) {
        isCloseButton = true;
      }
    });
    return isCloseButton;
  };

  return (
    <div className="figma-info-modal__wrapper">
      <div className={"figma-info-modal__backdrop" + (isOpen ? " figma-info-modal__backdrop--visible" : "")}>
        <div className={`figma-info-modal ${isOpen && "figma-info-modal--visible"}`} ref={ref}>
          <div className="figma-info-modal__header">
            {icon === "warning" && <RedWarningTriangle />}
            {icon === "email" && <Email />}
            <div className="figma-info-modal__text-wrapper">
              <h2 className="figma-info-modal__typography figma-info-modal__title">{title ?? ""}</h2>
              <p className="figma-info-modal__typography figma-info-modal__description">{message}</p>
            </div>
          </div>
          <div className="figma-info-modal__buttons-wrapper">
            {buttons.length > 0 && (
              <>
                {!isCloseButton() && (
                  <ButtonNew onClick={() => closeModal()} disabled={isLoading}>
                    Close
                  </ButtonNew>
                )}
                {buttons.map(({ name, mode, onClick, isCloseBtn }) => {
                  const props: Record<string, unknown> = {
                    onClick: () => (onClick ? onClick() : closeModal()),
                    loading: isCloseBtn ? false : isLoading,
                    disabled: isLoading,
                  };

                  if (mode) {
                    props[mode] = true;
                  }

                  return (
                    <ButtonNew {...props} key={name}>
                      {name}
                    </ButtonNew>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
