import { createMachine } from "xstate";
import { Editor } from "grapesjs";

import { EditorService } from "../editor/editor.service.ts";
import { projectsService } from "../../../../../machines/projects/projects.instance.ts";
import { pagesService } from "./pages.service.ts";

import { extractAllIdStyles } from "../../utils/extract-id-styles.ts";
import { preventDefaultZoom } from "../../../../../utils/index.ts";

export type EditorContext = { editor?: Editor; service: EditorService };
export const pagesMachine = (service: EditorService) =>
  createMachine<EditorContext>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QGEBKBRAggFXQfQFUBldVPAMQHlUBZAOiO01WwGIBtABgF1FQAHAPawAlgBcRggHZ8QAD0QBGACzK6ADgBM6xYoDsmzgDYjATlPqjAGhABPJQGZFGsw6169p5Z3UBWAL7+NmhYuIQkZFS0dOTo2MgAEngAigTojACSlAByRKwQ0mB0IlIAboIA1kUhOPjEpBTU9LHxSanp2Fm5CCXlAMYAhhLSXNyjskKiwzJI8ojKRmqmDnrKpoqceoq+yirKNvYIXhqm2pp66uoOvtu+eoHBGLXhDVHNcYkpaZk5eWAATv9BP86PwADZDABmwIAtnQamF6pEmjEPm1vp1fj0yoJBtNRuNZpNxJIZqAFAgFksVmsNlsdnsDogjA5OHRfL5zL5Wb4jOo9EYHiAEXUIo1ou0frlWERMAA1fCYXIAdVIhIEwhJ0lkFL8ahZ6mMmx2nB8miZCF8miMdE43lWpiM1sUmgcgqCwqeiLFbzoksx0uy6AAGtgvh0uuqQMTpjrEHq6KZjJp9G5tEYLhbdr5E9yXeY6ZZVkKRS9kRKMV08gAFDByrLEcNSqMx0lxhAJpNO1NaIvqC36NTKPOGLzWvnKEte0WvFGyhV4JVEVWofKFYo4qrw6dl8X0eeKlWkbH9Iakgk8Caa2OzXXHZTqZROF1GO7D812eZXdlu3ycTQ7H+r6KFOoQzuW9AZEQFAZNkmAADJNgGHCXkS15trejj6HQygplopp+CY-afpSdx0BciwsnonCKI+tEBB6pZInudBQTBcGIf6XQcIovBoVMGHklheg4Xhhg+LyfJZro5GWAsnB3Ga5igc8zG+kGoZIdxBRSEUvSVNUO5qSiGlhlxOQnriZ4jDwLbodqmEIIoTimHQ1ocuoFhJvoqwWucDiJgKkkrB4r6aCp3qztEtboPWlCNuZ2RrrpG7lFuTE+iiMVxQllYWfpeLnrZqEagJDlCR2RjODcHlbGczkWjoOHmOYbgCk6VoMY8YG7r6MXVswh7LqQeQ6Xpm6GT1xnRRgA0YIuR6oEQlmFTZYwldG9lknMTmrJo7JOu+2zeA4pxZmRnBugs5zaOYPgReBLFLiu0FPAAIgAmjK6DZG9C3DUtdlldtFIpqdOF8nop18qYkm+OdObcpRmxXNolhdZ6U2ZdEJC-f9L3JeNaWTap2P7j9f3PSNK3WVIF58aVWog4gAHg3cOjXGmdpeFmhi2pynl7KoZheA9vVzhT+MjawAJAiC4JQrC25Y1F5N41TS00-ixUM5twPtqzrns853L4Ws+wkTcAU0ay9EbFbIFClIggQHAsgZarV7645AC01gkX7tqmqavh+JsejclDGMexBDBMCwXtM+2uEDqyLjLHyOyaK6OwOGL03vK0Wm-InN4Va6rmnLRHiGJcnkDpsdBul4l1Vd4hrWvnZN+nluSl4JO0mCJVePrDEftwOig2lVY5uMYnmbF3qsMPKQ0rv35U7YWOHcsapzjwKWarLmTg3Pol0CtsS+x2x5CwQhxfZBvzNORsbKGgYKb6NnSbEYcuw2mHM+S+yxtjqGvixUyj9n7tm2BsOgh1TDUVoqcMKflLh0F0EgiO5gPDtwgX1OsDZoKJRgY5beQC97nB2IfEisMEFPmtEmM+LpuQEKyrNQaUslpkIqnAtkiDkGeXch+f+Dh9pI25DdWG11o5GW7hrV6WBPq8K3rDfaCx+TnFOJyXQWZxE-lfOIgwMi27sJxpLRRqjQbiJzNsQ6eY3QrH9mIiRV0HBPmwRcSw5jmiwSggkdAb1rEsyhs4R0-JqKcHWBmeGJFVBsiRqoZQeC-DUTkSrWOpBUDUBCQgfy4TIZRJiRHC0NxXLWlZNE9GtEHB50CP4IAA */
      predictableActionArguments: true,
      id: "ALPHA_O",
      initial: "START",
      context: {
        service,
      },
      states: {
        START: {
          on: {
            LOAD_PAGES: "READY",
          },
        },

        // LOAD_PAGES: {
        //   entry: ["updatePages", "notify"],
        //   on: {
        //     SELECT_PAGE: "SELECT_PAGE",
        //     ADD_PAGE: "ADD_PAGE",
        //     DELETE_PAGE: "DELETE_PAGE",
        //     DUPLICATE_PAGE: "DUPLICATE_PAGE",
        //   },
        // },

        SELECT_PAGE: {
          invoke: {
            src: "selectPage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        ADD_PAGE: {
          invoke: {
            src: "addPage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        RENAME_PAGE: {
          invoke: {
            src: "renamePage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DELETE_PAGE: {
          invoke: {
            src: "deletePage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DUPLICATE_PAGE: {
          invoke: {
            src: "duplicatePage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: ["updatePages", "notify"],
          on: {
            SELECT_PAGE: "SELECT_PAGE",
            ADD_PAGE: "ADD_PAGE",
            DELETE_PAGE: "DELETE_PAGE",
            RENAME_PAGE: "RENAME_PAGE",
            DUPLICATE_PAGE: "DUPLICATE_PAGE",
          },
        },

        FINISH: {
          type: "final",
        },

        ERROR: {},
        //
      },
    },
    {
      actions: {
        notify: () => {
          pagesService.notifySubscribers();
        },
        updatePages: ({ service }) => {
          //when editor will change number of pages get pages and update pagesService
          const editor = service.getEditor();
          if (editor) {
            const pages = service.getEditor()?.Pages.getAll();
            if (pages) {
              pagesService.state = pages;
            }
          }
        },
        //appendActions
      },
      services: {
        //appendServices
        selectPage: async ({ service }, event) => {
          const editor = service.getEditor();
          const { id } = event;

          if (editor?.Pages.getSelected()?.get("id") === id) return Promise.resolve();

          editor?.Pages.select(id);

          preventDefaultZoom(editor);

          // TODO - maybe is other way to add scrpits/meta to editor globally
          // editor.machine set script when editor.load() is called, and init script is added to main page;
          if (editor?.Canvas.getDocument().head.innerHTML !== "") {
            return Promise.resolve();
          }

          return Promise.resolve();
        },

        addPage: async ({ service }) => {
          const editor = service.getEditor();

          // classes we add in backend to first frame in html
          const firstOwnCompoenent =
            editor?.DomComponents.getWrapper()?.find(".local-styles-variables") ||
            editor?.DomComponents.getWrapper()?.find(".local-text-styles-variables");
          const selectorsWithVariables = firstOwnCompoenent?.[0].getSelectors().map((selector) => {
            const cassName = selector.getFullName();
            const styleObject =
              editor?.Css?.getRule(cassName)?.attributes.style ||
              editor?.Css?.getRule(`:root ${cassName}`)?.attributes.style;

            const hasVariables =
              !!styleObject &&
              Object.keys(styleObject).length > 0 &&
              Object.keys(styleObject).some((key) => key.startsWith("--"));

            return hasVariables ? selector.getName() : "";
          });

          const styles = "",
            component = "";

          const createdPage = editor?.Pages.add({ name: "Default page", styles, component });

          if (!createdPage) return Promise.resolve();

          // assign to pageWrapper classes with variables
          // its first component in page, that set up min height after
          // pasting element to editor it will be pasted to this compoennt
          const pageWrapper = createdPage?.getMainComponent();
          if (pageWrapper) {
            selectorsWithVariables?.forEach((className) => {
              if (className) {
                pageWrapper.addClass(className);
              }
            });
          }

          try {
            const activeProjectID = projectsService.getActiveProject()?.projectId;
            if (activeProjectID) {
              await projectsService.addPage(activeProjectID, {
                frameId: createdPage.get("id") || "",
                name: createdPage.get("name") || "",
              });
            }
          } catch (e) {
            console.error(e);
          } finally {
            return Promise.resolve();
          }
        },

        renamePage: async ({ service }, event) => {
          const { id, pageName } = event;
          const editor = service.getEditor();
          const activeProjectID = projectsService.getActiveProject()?.projectId;
          const page = editor?.Pages.get(id);

          if (!activeProjectID || !page) return Promise.resolve();

          try {
            await projectsService.renamePage(activeProjectID, id, pageName);
            page.set("name", pageName);
          } catch (e) {
            console.error(e);
          } finally {
            return Promise.resolve();
          }
        },

        deletePage: async ({ service }, event) => {
          const { id } = event;
          const editor = service.getEditor();
          const pages = editor?.Pages.getAll();
          const selectedPageId = editor?.Pages.getSelected()?.get("id");
          const activeProjectID = projectsService.getActiveProject()?.projectId;
          const isMainPage = editor?.Pages.getMain().get("id") === id;

          let newMainPageId = null;

          // if main page is deleted and we have more than one page, set new main page
          if (isMainPage && pages && pages.length > 1) {
            newMainPageId = pages[1].get("id") ?? null;
          }

          if (!activeProjectID) return Promise.resolve();

          try {
            await projectsService.deletePage(activeProjectID, id, newMainPageId);

            editor?.Pages.remove(id);
            // if try delete current page, select first page
            if (pages?.length && selectedPageId === id) {
              editor?.Pages.select(editor?.Pages.getAll()[0]);
            }
          } catch (e) {
            console.error(e);
          } finally {
            return Promise.resolve();
          }
        },

        duplicatePage: async ({ service }, event) => {
          const { id } = event;
          const editor = service.getEditor();
          const page = editor?.Pages.get(id);
          const activeProjectID = projectsService.getActiveProject()?.projectId;

          if (!activeProjectID || !page) return Promise.resolve();
          const pageMainCompoenent = page?.getMainComponent();
          // TODO if we want to duplicate all components, we need to get all components from page
          // but it break css logic with id becouse components are added with new id's
          // const components = editor?.DomComponents.getWrapper()?.get("components")?.toJSON();
          const pageHtml = editor?.getHtml({ component: pageMainCompoenent }) || "";
          const pageCss = editor?.getCss({ component: pageMainCompoenent }) || "";
          // classes are shared betwen pages, but id selectors are not
          const idSelectors = extractAllIdStyles(pageCss);

          try {
            const duplicatedPage = editor?.Pages.add({
              name: `${page.get("name")} - Copy`,
              styles: idSelectors,
              component: pageHtml,
            });

            if (duplicatedPage) {
              await projectsService.addPage(activeProjectID, {
                frameId: duplicatedPage.get("id") || "",
                name: duplicatedPage.get("name") || "",
              });
            }
          } catch (e) {
            console.error(e);
          } finally {
            return Promise.resolve();
          }
        },
      },

      guards: {
        //appendGuards
      },
    }
  );
