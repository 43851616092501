import { useEffect, useState } from "react";
import { Switch } from "../../../../../../../AlphaO/atoms/Switch";
import { Button } from "../../../../../../../AlphaO/atoms/Button";
import { Radio } from "../../partials/Radio";
import { editorService } from "../../../../editor";
import { assetManagerInstance } from "../../../../asset-manager";

type HTMLAttributes = {
  class: string;
  id: string;
  poster: string;
  videoProvider: string;
  autoplay: boolean;
  loop: boolean;
  controls: boolean;
  src: string;
  muted: boolean;
};

const sourceOptions = [
  {
    id: "internal",
    value: "URL",
  },
  {
    id: "external",
    value: "Upload",
  },
];

const sanitizeVideoAttribute = (input: string | undefined) => {
  if (input === undefined) {
    return "";
  }
  if (!!input.match(/^https:\/\/codejet-(dev|stage|prod).s3./g)) {
    return "";
  }
  return input;
};

export const HTML5 = () => {
  const [attributes, setAttributes] = useState<HTMLAttributes>({
    class: "",
    id: "",
    poster: "",
    videoProvider: "",
    autoplay: false,
    loop: false,
    controls: false,
    muted: true,
    src: "",
  });
  const [source, setSource] = useState(sourceOptions[0]);

  const updateAttribute = (name: keyof HTMLAttributes, value: any) => {
    setAttributes((prev) => {
      let newAttributes = { ...prev, [name]: value };
      const editor = editorService.editor;
      if (editor) {
        const selected = editor.getSelected();
        if (selected) {
          const selectedChildren = selected.getLastChild();
          if (selectedChildren) {
            const attributes = selectedChildren.getAttributes();
            newAttributes = { ...attributes, [name]: value } as HTMLAttributes;
            selectedChildren.setAttributes(newAttributes);
          }
        }
      }

      return newAttributes;
    });
  };

  useEffect(() => {
    const editor = editorService.editor;
    if (editor) {
      const selected = editor.getSelected();
      if (selected) {
        const selectedChildren = selected.getLastChild();
        if (selectedChildren) {
          const attributes = selectedChildren.getAttributes();
          setAttributes(attributes as HTMLAttributes);
        }
      }
    }
  }, []);

  const handleChangeFile = () => {
    assetManagerInstance.send("UPLOAD_VIDEO");
  };

  const handleChangePoster = () => {
    assetManagerInstance.send("UPLOAD_POSTER");
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value as (typeof sourceOptions)[number]["value"];
    setSource(sourceOptions.find((option) => option.value === value) as (typeof sourceOptions)[number]);
  };

  return (
    <>
      <li className="trait-manager__trait">
        <label className="trait-manager__trait-label">Source</label>
        <Radio options={sourceOptions} currentOption={source} onChange={handleChange} />
      </li>
      <>
        <li className="trait-manager__trait">
          <label className="trait-manager__trait-label">{source.id === "internal" ? "File URL" : "Select File"}</label>
          {source.id === "internal" ? (
            <input
              placeholder="eg. http://exmple.com/video.mp4"
              value={sanitizeVideoAttribute(attributes.src)}
              onChange={(e) => updateAttribute("src", e.currentTarget.value)}
            />
          ) : (
            <Button type="tertiary" onClick={handleChangeFile}>
              Upload
            </Button>
          )}
        </li>
        <li className="trait-manager__trait">
          <label className="trait-manager__trait-label">
            {source.id === "internal" ? "Cover URL" : "Select Cover"}
          </label>
          {source.id === "internal" ? (
            <input
              placeholder="eg. http://exmple.com/poster.png"
              value={sanitizeVideoAttribute(attributes.poster)}
              onChange={(e) => updateAttribute("poster", e.currentTarget.value)}
            />
          ) : (
            <Button type="tertiary" onClick={handleChangePoster}>
              Upload
            </Button>
          )}
        </li>
      </>
      {(["autoplay", "loop", "controls", "muted"] as const).map((option) => (
        <li key={option} className="trait-manager__trait trait-manager__trait--horizontal">
          <span className="trait-manager__checkbox-label">{option.charAt(0).toUpperCase() + option.slice(1)}</span>
          <Switch isActive={attributes[option]} onChange={() => updateAttribute(option, !attributes[option])} />
        </li>
      ))}
    </>
  );
};
