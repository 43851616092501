import { Project } from "../types/types";

export const getPublishedDomainAddress = (project: Project | undefined) => {
  if (!project) return null;

  if (project?.customDomain?.published) {
    return project?.customDomain?.address;
  }

  if (project?.subdomain?.published) {
    return project?.subdomain?.address;
  }

  return null;
};
