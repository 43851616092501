import { Cancel } from "../../../../../../../assets/svg/Cancel";
import { ToastInfoIcon } from "../../../../../../../assets/svg/ToastInfoIcon";

import { TToastHeader } from "./ToastHeader.types";

import "./ToastHeader.css";

export const ToastHeader: TToastHeader = ({ onClose, title, description, hint }) => {
  const getToastHeaderStyleModifier = () => {
    if (hint && onClose) return " toast-header--3col";
    if (hint) return " toast-header--with-hint";
    if (onClose) return " toast-header--with-close-icon";
    return null;
  };

  const classModifier = getToastHeaderStyleModifier();

  return (
    <header className={`toast-header${classModifier ? classModifier : ""}`}>
      {hint && <ToastInfoIcon />}
      <div className="toast-header__wrapper">
        <h2 className="toast-header__title">{title}</h2>
        <p className="toast-header__description">{description}</p>
      </div>
      {onClose && (
        <button className="toast-header__close" onClick={onClose}>
          <Cancel />
        </button>
      )}
    </header>
  );
};
