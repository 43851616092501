import { type SVGProps } from "react";

export const Desktop = (props: SVGProps<SVGSVGElement>) => (
  <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ddd_20695_8093)">
      <rect x="22" y="20.5" width="56" height="56" rx="12" fill="url(#paint0_linear_20695_8093)" />
      <rect x="22" y="20.5" width="56" height="56" rx="12" fill="url(#paint1_linear_20695_8093)" />
      <rect
        x="22.5"
        y="21"
        width="55"
        height="55"
        rx="11.5"
        stroke="url(#paint2_linear_20695_8093)"
        strokeOpacity="0.6"
      />
      <path
        d="M40 57.5H55M59 57.5H60M40 52.9V40.1C40 39.7686 40.2686 39.5 40.6 39.5H59.4C59.7314 39.5 60 39.7686 60 40.1V52.9C60 53.2314 59.7314 53.5 59.4 53.5H40.6C40.2686 53.5 40 53.2314 40 52.9Z"
        stroke="#FF4900"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_20695_8093"
        x="0"
        y="0.5"
        width="100"
        height="100"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20695_8093" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_20695_8093" result="effect2_dropShadow_20695_8093" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_20695_8093" result="effect3_dropShadow_20695_8093" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_20695_8093" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_20695_8093" x1="50" y1="20.5" x2="50" y2="76.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C2C2F" />
        <stop offset="1" stopColor="#222225" />
      </linearGradient>
      <linearGradient id="paint1_linear_20695_8093" x1="50" y1="20.5" x2="50" y2="76.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient id="paint2_linear_20695_8093" x1="50" y1="20.5" x2="50" y2="76.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
