import { AddComponentTypeOptions } from "grapesjs";

export const componentsDefinitions: { id: string; props: AddComponentTypeOptions }[] = [
  {
    id: "flex-section",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["flex-section"],
          styles: `
          .flex-section { display: flex; flex-direction: row; padding: 10px; }
          `,
          traits: [
            {
              type: "class_select",
              options: [
                { value: "flex-container", name: "Flex Container", id: "flex-container" },
                { value: "flex-item", name: "Flex Item", id: "flex-item" },
              ],
              label: "Flex Type",
            },
          ],
        },
      },
      view: {},
    },
  },
  {
    id: "cjRow",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-grid-row"],
          styles: `
          .cj-grid-row { display: flex; justify-content: flex-start; align-items: stretch; min-height: auto; padding: 10px 0; };
          `,
          // for future use
          // traits: [
          //   {
          //     type: "class_select",
          //     options: [
          //       { value: "block", name: "Block", id: "block" },
          //       { value: "block--red", name: "Block Red", id: "block--red" },
          //       { value: "block--green", name: "Block Green", id: "block--green" },
          //       { value: "block--blue", name: "Block Blue", id: "block--blue" },
          //     ],
          //     label: "Block Type",
          //   },
          // ],
        },
      },
      view: {},
    },
  },
  {
    id: "cjColumn",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-grid-column"],
          styles: `
          .cj-grid-column { flex: 1 1 0;}
          .cj-grid-column--3 { flex: 0 0 33%; }
          `,
          // for future use
          // traits: [
          //   {
          //     type: "class_select",
          //     options: [
          //       { value: "block", name: "Block", id: "block" },
          //       { value: "block--red", name: "Block Red", id: "block--red" },
          //       { value: "block--green", name: "Block Green", id: "block--green" },
          //       { value: "block--blue", name: "Block Blue", id: "block--blue" },
          //     ],
          //     label: "Block Type",
          //   },
          // ],
        },
      },
      view: {},
    },
  },
  {
    id: "cjSection",
    props: {
      model: {
        defaults: {
          tagName: "section",
          classes: ["cj-section"],
          styles: `
          .cj-section { width: 100%; padding: 50px 0; };
          `,
        },
      },
    },
  },
  {
    id: "cjContainer",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-container"],
          styles: `
          .cj-container { width: 90%; max-width: 1200px; margin: 0 auto; };
          `,
        },
      },
    },
  },
  {
    id: "Separator",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-separator"],
          styles: `
          .cj-separator { width: auto;  height: 3px; margin: 1rem; background-color: #FF4900; }
          `,
        },
      },
    },
  },
  {
    id: "link",
    props: {
      model: {
        defaults: {
          tagName: "a",
          classes: ["cj-link"],
          attributes: { href: "e.g. https://www.codejet.ai/" },
          styles: `
          .cj-link { color: inherit; display: inline-block; vertical-align: top; max-width: 100%; text-decoration: none; }
          `,
        },
      },
    },
  },
  {
    id: "link-box",
    props: {
      model: {
        defaults: {
          tagName: "a",
          classes: ["link-box"],
          attributes: { href: "https://www.codejet.ai/" },
          styles: `
          .link-box { color: inherit; display: inline-block; vertical-align: top; max-width: 100%; text-decoration: none; }
          `,
        },
      },
    },
  },
  {
    id: "map",
    props: {
      model: {
        defaults: {
          tagName: "iframe",
          classes: ["map"],
          attributes: {
            id: "map",
            src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2395.185028003585!2d23.19080587774318!3d53.10687007222087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ff9a60ca14f73%3A0xceee3699193cd410!2sCodejet!5e0!3m2!1spl!2spl!4v1713358615087!5m2!1spl!2spl",
            allowfullscreen: "",
            loading: "lazy",
            referrerpolicy: "no-referrer-when-downgrade",
          },
          styles: `
          .map { width: 600px;  height: 450px; border: 0; }
          `,
        },
      },
    },
  },
  {
    id: "codejetVideo",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["video-wrapper"],
          components: [
            {
              tagName: "iframe",
              classes: ["iframe-no-pointer"],
              attributes: {
                src: "",
                frameborder: 0,
                allowfullscreen: "",
                width: "",
                height: "",
                title: "YouTube video player",
                style: "width: 100%; height: 100%;",
              },
              resizable: true,
              draggable: false,
              droppable: false,
              selectable: false,
              editable: true,
            },
          ],
          traits: [
            "id",
            "title",
            {
              type: "text",
              label: "Youtube Video URL",
              name: "src",
            },
          ],
          attributes: { src: "https://www.youtube.com/embed/b82QGj0BWH8?si=WJd08UKjGKOyLoN7", title: "Codejet Video" },
          // content: "<iframe></iframe>",
          styles: `
          .video-wrapper { max-width: 1024px; width: 100%; height: auto; aspect-ratio: 16/9; }
          `,
        },
        init() {
          this.on("change:attributes:src", this.handleSrcChange);
        },
        handleSrcChange() {
          const src = this.getAttributes().src;
          const iframe = this.find("iframe")[0];
          iframe?.addAttributes({ src });
        },
      },
      view: {
        onRender() {
          const iframe = this.model.find("iframe")[0];
          const { src } = this.model.getAttributes();
          iframe?.addAttributes({
            src: src || "",
          });
          // need to add this if someone refresh page on preview mode
          iframe.addClass("iframe-no-pointer");
        },
      },
    },
  },
  {
    id: "form",
    props: {
      model: {
        defaults: {
          tagName: "form",
          classes: ["cj-form"],
          traits: [
            {
              type: "select",
              name: "method",
              label: "Method",
              options: [
                { id: "GET", value: "GET" },
                { id: "POST", value: "POST" },
              ],
            },
            {
              type: "text",
              label: "action",
              name: "Action",
            },
            {
              type: "text",
              label: "On Form Submit",
              name: "onsubmit",
            },
            {
              type: "text",
              label: "Form ID",
              name: "id",
            },
          ],
          attributes: { method: "GET", onsubmit: `alert("Form submitted")`, action: "" },
          styles: `.cj-form { display: flex; flex-direction: column; align-items: flex-start; gap: 0.25rem; padding: 1rem; background-color: var(--colours_natural_mid-gray, #ffffff); border-radius: 8px;}`,
        },
      },
    },
  },
  {
    id: "label",
    props: {
      model: {
        defaults: {
          tagName: "label",
          classes: ["cj-label"],
          traits: ["id", "title", "for"],
          styles: `
          .cj-label { color: var(--colours_text--icon_deep-darker-gray, #000000) ; font-family: Arial; font-size: 14px; font-weight: 400; line-height: 22px; text-align: left; vertical-align: top; }
          `,
        },
      },
    },
  },
  {
    id: "input",
    props: {
      model: {
        defaults: {
          tagName: "input",
          classes: ["cj-input"],
          traits: [
            "id",
            "title",
            "name",
            "placeholder",
            {
              type: "checkbox",
              name: "required",
              label: "Required",
            },
          ],
          attributes: { placeholder: "Enter your text here" },
          styles: `
          .cj-input { outline: none; background-color: transparent; font-size: 16px; font-family: Arial; font-weight: 400; letter-spacing: 0%; line-height: 28px; color: var(--colours_text--icon_deep-darker-gray, #000000); padding: 16px 24px; border: 1px solid rgb(101, 101, 101);border-radius: 12px; overflow: hidden; }
          .cj-input::placeholder { color: var(--colours_text--icon_deep-darker-gray, #000000) ; opacity: 0.4; }
          .cj-input:focus { border-top-color: var(--colours_brand_primary-500, #FF4900); border-right-color: var(--colours_brand_primary-500, #FF4900); border-bottom-color: var(--colours_brand_primary-500, #FF4900); border-left-color: var(--colours_brand_primary-500, #FF4900); }
          `,
        },
      },
    },
  },
  {
    id: "select",
    props: {
      model: {
        defaults: {
          tagName: "select",
          classes: ["cj-select"],
          traits: ["id", "title", "name", { type: "select-options", label: "Options" }],
          styles: `
          .cj-select { color: var(--colours_text--icon_deep-darker-gray, #000000) ; padding: 16px 24px; border-radius: 8px; overflow: hidden; }
          .cj-select option { color: var(--colours_text--icon_deep-darker-gray, #000000) ;padding: 16px 24px; }
          `,
        },
      },
      view: {
        // dropdown is not selectable in editor
        onRender() {
          this.el.addEventListener("mousedown", (e) => {
            e.preventDefault();
          });
        },
      },
    },
  },
  {
    id: "option",
    props: {
      model: {
        defaults: {
          tagName: "option",
          classes: ["cj-option"],
          styles: `
          .cj-option { color: var(--colours_text--icon_deep-darker-gray, #000000) ;padding: 0.5rem; }
          `,
          layerable: false,
        },
      },
    },
  },
  {
    id: "button",
    props: {
      isComponent: (el) => el.tagName === "BUTTON",
      model: {
        defaults: {
          traits: ["id", "data-redirect"],
          tagName: "button",
          classes: ["cj-button"],
          styles: `
          .cj-button { display: flex; flex-direction: row; align-items: center; justify-content: center; flex-wrap: nowrap; gap: 12px; padding: 18px; background-color: var(--primary-500, #FF4900); border: none; border-radius: 12px; }
          .cj-button:hover { background-image: var(--primary-700, #FF4900); }
          .cj-button:focus { background-image: var(--primary-900, #FF4900); }
          .cj-button span { color: var(--colourstext--iconwhite, #ffffff); font-family: Arial; font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; vertical-align: top; }
          `,
        },
      },
    },
  },

  {
    id: "checkbox",
    props: {
      model: {
        defaults: {
          tagName: "input",
          attributes: {
            type: "checkbox",
          },
          classes: ["cj-checkbox"],
          traits: [
            "id",
            "title",
            "name",
            {
              type: "checkbox",
              name: "required",
              label: "Required",
            },
          ],
          styles: `.cj-checkbox { width: 20px; height: 20px; border: 2px solid rgba(101, 101, 101, 1); border-radius: 2px; background-color: transparent; }
          .cj-checkbox:checked { border-color: var(--colours_brand_primary-500, #FF4900); accent-color: var(--colours_brand_primary-500, #FF4900); }
          `,
        },
      },
    },
  },
  {
    id: "radio",
    props: {
      model: {
        defaults: {
          tagName: "input",
          attributes: {
            type: "radio",
          },
          classes: ["cj-radio"],
          styles: `
            .cj-radio { width: 20px; height: 20px; }
            .cj-radio:checked { accent-color: var(--colours_brand_primary-500, #FF4900); }
          `,
        },
      },
    },
  },
  {
    id: "textfield",
    props: {
      model: {
        defaults: {
          tagName: "textarea",
          classes: ["cj-textfield"],
          styles: `
          .cj-textfield { outline: none; background-color: transparent;   font-size: 16px; font-family: Arial; font-weight: 400; letter-spacing: 0%; line-height: 28px; color: var(--colours_text--icon_deep-darker-gray, #000000); padding: 16px 24px; border: 1px solid rgb(101, 101, 101);border-radius: 12px; overflow: hidden; }
          .cj-textfield::placeholder { color: var(--colours_text--icon_deep-darker-gray, #000000) ; opacity: 0.4; }
          .cj-textfield:focus { border-top-color: var(--colours_brand_primary-500, #FF4900); border-right-color: var(--colours_brand_primary-500, #FF4900); border-bottom-color: var(--colours_brand_primary-500, #FF4900); border-left-color: var(--colours_brand_primary-500, #FF4900 ); }
          `,
        },
      },
    },
  },
  {
    id: "cjNavbar",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-navbar"],
          styles: `
          .cj-navbar { display: flex; justify-content: space-between; align-items: center; width: 90%; margin: 0 auto; padding: 1rem 0; }
          .cj-navbar__nav { display: flex: gap: 1rem; }
          `,
        },
      },
    },
  },
];
