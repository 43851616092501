import { modalMachineInstance } from "../..";
import { routerInstance } from "../../../../../../../machines/router/router.instance";

import { ToastFooter } from "../../partials/ToastFooter";
import { ToastHeader } from "../../partials/ToastHeader/ToastHeader";
import { Button } from "../../../../../../AlphaO/atoms/Button";

import { AiStars } from "../../../../../../../assets/svg/AiStars";

import type { MouseEvent } from "react";

import "./TemplateWelcomeModal.css";

export const TemplateWelcomeModal = () => {
  const handleCancel = () => {
    modalMachineInstance.send("CLOSE");
  };
  const handleBackTemplates = () => {
    routerInstance.send("PICK_TEMPLATE");
  };

  const handleAiModalClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    modalMachineInstance.send("CHANGE_VIEW", { viewId: "ai-notify-modal" });
  };

  return (
    <div className="template-welcome-toast new-colors">
      <ToastHeader
        title={"Do you like this template?"}
        description={
          "You can easily choose another one from our collection or create your own using artificial intelligence"
        }
      />
      <div className="template-welcome-toast__body">
        <Button type="big/secondary" size="small" onClick={handleBackTemplates}>
          Select another template
        </Button>
        <div className="separator">
          <div className="separator-line"></div>
          <span className="separator-text">or</span>
          <div className="separator-line"></div>
        </div>
        <Button type="big/stroke" size="small" iconStart={<AiStars />} onClick={handleAiModalClick}>
          Generate with AI
        </Button>
      </div>
      <ToastFooter onSecondaryCta={handleCancel} secondaryCtaText="Cancel" />
    </div>
  );
};
