import { modalMachineInstance } from "../..";

// import { ToastFooter } from "../../partials/ToastFooter";
import { ToastHeader } from "../../partials/ToastHeader/ToastHeader";

import { CircleCheck } from "../../../../../../../../assets/svg/circle-check";

import "./AiNotifySuccessModal.css";

export const AiNotifySuccessModal = () => {
  const handleClose = () => {
    modalMachineInstance.send("CLOSE");
  };

  return (
    <div className="ai-notify-success-toast new-colors">
      <div className="ai-notify-success-toast__icon">
        <CircleCheck />
      </div>
      <ToastHeader title={"Request sent"} description={"We'll notify you once ready"} onClose={handleClose} />
    </div>
  );
};
