import { SubscriptionTypes, SubscriptionInfo } from "@shared/types/projects.interfaces";
import { Project } from "../../types/types";

import { SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPE } from "@shared/constants/subscription";

/**
 * Retrieves the subscription details of a specific type from a given project.
 *
 * @param project - The project object containing subscription information.
 * @param type - The type of subscription to retrieve details for.
 * @returns An object containing the subscription details of the specified type.
 */
export const getSubscriptionsDetailsByType = (project: Project | undefined, type: SubscriptionTypes) => {
  const subscriptionsDetails: SubscriptionInfo = {
    type,
    priceId: "",
    id: "",
    status: type === SUBSCRIPTION_TYPE.site_sub ? SUBSCRIPTION_STATUS.active : SUBSCRIPTION_STATUS.canceled, // Default status is SUBSCRIPTION_STATUS.active for site subscriptions and "canceled" for others
    currentPeriodStart: 0,
    currentPeriodEnd: 0,
    isClosed: false,
    nextPlanPriceId: "",
  };

  if (!project) {
    return subscriptionsDetails;
  }

  for (const subscription of project.subscriptions) {
    if (subscription.type === type) {
      subscriptionsDetails.priceId = subscription.priceId;
      subscriptionsDetails.status = subscription.status;
      subscriptionsDetails.id = subscription.id;
      subscriptionsDetails.currentPeriodStart = subscription.currentPeriodStart;
      subscriptionsDetails.currentPeriodEnd = subscription.currentPeriodEnd;
      subscriptionsDetails.type = subscription.type;
      subscriptionsDetails.isClosed = subscription.isClosed;
      subscriptionsDetails.nextPlanPriceId = subscription.nextPlanPriceId;
    }
  }

  return subscriptionsDetails;
};
