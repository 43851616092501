import dayjs from "dayjs";

import { useEffect, useState } from "react";
import { projectsInstance } from "../../../../machines/projects/projects.instance";

import { NoContent } from "./NoContent";
import { ProjectCard } from "../../atoms/ProjectCard";

import { getSubscriptionsDetailsByType } from "../../../../utils/getSubscriptionsDetails/getSubscriptionsDetails";

import { Project } from "../../../../types/types";
import { Modal } from "../../atoms/ProjectCard/components/Modal";

import { SubscriptionStatusValues } from "@shared/types/projects.interfaces";

import { SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPE } from "@shared/constants/subscription";

export const RenderContent = ({
  isSkeleton,
  isNoContent,
  projects,
}: {
  isSkeleton: boolean;
  isNoContent: boolean;
  projects: Project[];
}) => {
  const [isAddingProjectModalOpen, setIsAddingProjectModalOpen] = useState(false);
  const getWebSubscriptionEndsDate = (project: Project) => {
    const subscriptions = project.subscriptions;
    let currentPeriodEnd = 0;
    let isSubscriptionCancelled = false;
    let subscriptionStatus: SubscriptionStatusValues = "";

    if (subscriptions.length) {
      for (const sub of subscriptions) {
        sub.status;
        if (sub.type === SUBSCRIPTION_TYPE.site_sub) {
          currentPeriodEnd = sub.currentPeriodEnd;
          isSubscriptionCancelled = sub.isClosed ?? false;
          subscriptionStatus = sub.status;
        }
      }
    }

    if (currentPeriodEnd && isSubscriptionCancelled) {
      return `Expires: ${dayjs(currentPeriodEnd * 1000).format("MMM D, YYYY")}`;
    }

    if (currentPeriodEnd && !isSubscriptionCancelled) {
      // when payment is failed
      if (subscriptionStatus !== SUBSCRIPTION_STATUS.active) {
        return `Expires: ${dayjs(currentPeriodEnd * 1000).format("MMM D, YYYY")}`;
      }
      return `Ongoing`;
    }

    if (!currentPeriodEnd) {
      return "Free forever";
    }
  };

  useEffect(() => {
    const isProjectAdded = localStorage.getItem("isProjectAdded");
    if (isProjectAdded && JSON.parse(isProjectAdded)) {
      localStorage.removeItem("isProjectAdded");
      setIsAddingProjectModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (isSkeleton === false) {
      setIsAddingProjectModalOpen(false);
    }
  }, [isSkeleton]);

  if (isSkeleton) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ProjectCard key={index} skeleton style={{ animationDelay: `calc(${index} * 250ms)` }} />
        ))}
        {isAddingProjectModalOpen && (
          <Modal
            onClose={() => setIsAddingProjectModalOpen(false)}
            onClick={() => setIsAddingProjectModalOpen(false)}
            isLoading={true}
            loaderText="We are adding your project"
          />
        )}
      </>
    );
  } else if (isNoContent) {
    return <NoContent />;
  } else {
    const lockedStatuses: SubscriptionStatusValues[] = [
      SUBSCRIPTION_STATUS.incomplete,
      SUBSCRIPTION_STATUS.incomplete_expired,
      SUBSCRIPTION_STATUS.past_due,
      SUBSCRIPTION_STATUS.unpaid,
    ];
    const essentialPlanPriceID = import.meta.env.VITE_SITE_PLAN_PERSONAL_ID;
    const proPlanPriceID = import.meta.env.VITE_SITE_PLAN_BUSINESS_ID;
    const getPlan = (priceId: string) => {
      switch (priceId) {
        case "":
          return "starter";
        case essentialPlanPriceID:
          return "essential";
        case proPlanPriceID:
          return "pro";
        default:
          return "starter";
      }
    };
    return (
      <>
        {projects.map((project, idx) => {
          const { priceId, status } = getSubscriptionsDetailsByType(project, SUBSCRIPTION_TYPE.site_sub);
          return (
            <ProjectCard
              key={project.projectId}
              name={project.name}
              description={project.description}
              timestamp={project.lastUpdated || project.timestamp}
              webSubscriptionEnds={getWebSubscriptionEndsDate(project)}
              thumbnail={project.thumbnail}
              isLocked={lockedStatuses.includes(status)}
              isPublished={project?.subdomain?.published || project?.customDomain?.published}
              onClick={() => projectsInstance.send("SELECT_PROJECT", { projectId: project.projectId })}
              handlePlanUpgrade={() =>
                projectsInstance.send("PROJECT_SETTINGS_PLANS", { projectId: project.projectId })
              }
              onEdit={() => projectsInstance.send("PROJECT_SETTINGS", { projectId: project.projectId })}
              onDelete={async () =>
                await projectsInstance.getSnapshot().context.service.deleteProject(project.projectId)
              }
              onDuplicate={async () =>
                await projectsInstance.getSnapshot().context.service.duplicateProject(project.projectId)
              }
              style={{ animationDelay: `calc(${idx} * 250ms)`, zIndex: `${996 - idx}` }}
              plan={getPlan(priceId)}
            />
          );
        })}
      </>
    );
  }
};
