import { Button } from "../../../../../../AlphaO/atoms/Button";

import { TToastFooter } from "./ToastFooter.types";

import "./ToastFooter.css";

export const ToastFooter: TToastFooter = ({
  onCancel,
  onPrimaryCta,
  onSecondaryCta,
  primaryCtaText = "Primary label",
  secondaryCtaText = "Secondary label",
}) => {
  return (
    <footer className="toast-footer">
      {onCancel && (
        <Button type="big/text" size="small" onClick={onCancel}>
          Cancel
        </Button>
      )}
      {onPrimaryCta && (
        <Button type="big/primary" size="small" onClick={onPrimaryCta}>
          {primaryCtaText}
        </Button>
      )}
      {onSecondaryCta && (
        <Button type="big/secondary" size="small" onClick={onSecondaryCta}>
          {secondaryCtaText}
        </Button>
      )}
    </footer>
  );
};
