export const ACCOUNT_DELETION_INFO = {
  title: "Do you want to delete your account?",
  desc: "Send me a link to my email that will allow me to delete my account from the service",
};

export const SUCCESS_SENDING_DELETION_LINK = {
  title: "Check your inbox",
  desc: "Link to confirm the deletion of your account has been sent to email associated with this account",
};

export const LINK_DELETION_REQ_LIMIT_REACHED = {
  title: "Request Limit Reached",
  desc: "You can request an account deletion link only once per day. Today's limit has been reached. Please check your email inbox to find the previously sent link. If you cannot find the message, make sure to check your Spam or Promotions folder.",
};

export const FAILURE_SENDING_DELETION_LINK = {
  title: "Error",
  desc: "An error occurred while processing your request. Please try again later.",
};
