import { FigmaInfoModalBtns } from "../FigmaInfoModal.types";

type ConfigVariants = "close" | "cancel" | "sendLink";

export const BUTTONS_CONFIG: Record<
  ConfigVariants,
  (onClick?: () => unknown, isLoading?: boolean) => FigmaInfoModalBtns
> = {
  close: () => ({ name: "Close", isCloseBtn: true }),
  cancel: () => ({ name: "Cancel", isCloseBtn: true }),
  sendLink: (onClick?: () => unknown, isLoading?: boolean) => ({
    name: "Send link to delete",
    mode: "error",
    onClick,
    loading: isLoading,
    disabled: isLoading,
  }),
  // here you can add more buttons
};
