import { assign, createMachine } from "xstate";
import { BlockProperties, Component, Editor, grapesjs } from "grapesjs";
import { editorConfig } from "../../editor.config.ts";
import { EditorService } from "./editor.service.ts";
import { pagesService } from "../pages/pages.service.ts";
import { projectsService } from "../../../../../machines/projects/projects.instance.ts";
import { assetManagerInstance, assetManagerService } from "../asset-manager";
import { modalMachineInstance } from "../modal/index.ts";
import { extractComponents } from "../../utils/extract-components.ts";
import { extractVariables } from "../../utils/extract-variables.ts";
import { UserService } from "../../../../../services/UserService/UserService.ts";
import { replaceScriptTag } from "../../utils/replaceScriptTag.ts";
import { CODEJET_BADGE_SCRIPT } from "../../utils/codejetBadgeScript.ts";
import {
  loadProject,
  fallbackLoadProject,
  handleCustomCode,
  addScriptsToEditor,
  manageSmartNavbarPlacement,
  createIconStylesRule,
  addToastScriptToEditor,
} from "./helpers.ts";
import { preventDefaultZoom } from "../../../../../utils";

import { stylesManagerInstance } from "../styles-manager/index.ts";
import { styleManagerService } from "../styles-manager/StylesManager.service.ts";
import { selectorManagerInstance } from "../selector-manager/selector-manager.service.ts";
import { getBlacklistedComponents } from "../../../../../services/analytics";
import { Projects } from "../../../../../services/Projects/Projects.ts";
import { Templates } from "../../../../../services/Templates/Templates.ts";
import { templatesService } from "../../../../../machines/templates/templates.instance.ts";
import { TriggerService } from "../../../../../services/TriggerService/TriggerService.ts";

import { ProjectPublish, PublishPage } from "../../../../../types/types.ts";
import { IPageScripts } from "@shared/types/projects.interfaces.ts";

import { TOAST_NOTIFICATIONS_PACKAGE_URL } from "./components-definitions/smart-component-form/constants.ts";

import { formToastStyle } from "./components-definitions/smart-component-form/toast-style.ts";

const userService = UserService.getInstance();
const triggerService = TriggerService.getInstance();

/**
 * Splits the given text into lines (accounting for different operating systems),
 * then returns the first line containing the specified pattern, or null if none is found.
 *
 * @param text    The full text to search through.
 * @param pattern The substring pattern to look for in each line.
 * @returns       The first line containing the pattern, or null if not found.
 */
function findLineContainingPattern(text: string, pattern: string): string | null {
  // Split the text into lines, accounting for both Windows (\r\n) and Unix (\n) line breaks
  const lines = text.split(/\r?\n/);

  // Search for the first line that contains the pattern
  for (const line of lines) {
    if (line.includes(pattern)) {
      return line;
    }
  }

  // If no line contains the pattern, return null
  return null;
}

export type EditorContext = {
  editor?: Editor;
  service: EditorService;
  projectService: Projects | Templates;
  domainToUnpublish: "subdomain" | "customDomain";
};
export const editorMachine = (service: EditorService) =>
  createMachine<EditorContext>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QGEBKBRAggFXQfQFUBldVPAMQHlUBZAOiO01WwGIBtABgF1FQAHAPawAlgBcRggHZ8QAD0QBGACzK6ADgBM6xYoDsmzgDYjATlPqjAGhABPJQGZFGsw6169p5Z3UBWAL7+NmhYuIQkZFS0dOTo2MgAEngAigTojACSlAByRKwQ0mB0IlIAboIA1kUhOPjEpBTU9LHxSanp2Fm5CCXlAMYAhhLSXNyjskKiwzJI8ojKRmqmDnrKpoqceoq+yirKNvYIXhqm2pp66uoOvtu+eoHBGLXhDVHNcYkpaZk5eWAATv9BP86PwADZDABmwIAtnQamF6pEmjEPm1vp1fj0yoJBtNRuNZpNxJIZqAFAgFksVmsNlsdnsDogjA5OHRfL5zL5Wb4jOo9EYHiAEXUIo1ou0frlWERMAA1fCYXIAdVIhIEwhJ0lkFL8ahZ6mMmx2nB8miZCF8miMdE43lWpiM1sUmgcgqCwqeiLFbzoksx0uy6AAGtgvh0uuqQMTpjrEHq6KZjJp9G5tEYLhbdr5E9yXeY6ZZVkKRS9kRKMV08gAFDByrLEcNSqMx0lxhAJpNO1NaIvqC36NTKPOGLzWvnKEte0WvFGyhV4JVEVWofKFYo4qrw6dl8X0eeKlWkbH9Iakgk8Caa2OzXXHZTqZROF1GO7D812eZXdlu3ycTQ7H+r6KFOoQzuW9AZEQFAZNkmAADJNgGHCXkS15trejj6HQygplopp+CY-afpSdx0BciwsnonCKI+tEBB6pZInudBQTBcGIf6XQcIovBoVMGHklheg4Xhhg+LyfJZro5GWAsnB3Ga5igc8zG+kGoZIdxBRSEUvSVNUO5qSiGlhlxOQnriZ4jDwLbodqmEIIoTimHQ1ocuoFhJvoqwWucDiJgKkkrB4r6aCp3qztEtboPWlCNuZ2RrrpG7lFuTE+iiMVxQllYWfpeLnrZqEagJDlCR2RjODcHlbGczkWjoOHmOYbgCk6VoMY8YG7r6MXVswh7LqQeQ6Xpm6GT1xnRRgA0YIuR6oEQlmFTZYwldG9lknMTmrJo7JOu+2zeA4pxZmRnBugs5zaOYPgReBLFLiu0FPAAIgAmjK6DZG9C3DUtdlldtFIpqdOF8nop18qYkm+OdObcpRmxXNolhdZ6U2ZdEJC-f9L3JeNaWTap2P7j9f3PSNK3WVIF58aVWog4gAHg3cOjXGmdpeFmhi2pynl7KoZheA9vVzhT+MjawAJAiC4JQrC25Y1F5N41TS00-ixUM5twPtqzrns853L4Ws+wkTcAU0ay9EbFbIFClIggQHAsgZarV7645AC01gkX7tqmqavh+JsejclDGMexBDBMCwXtM+2uEDqyLjLHyOyaK6OwOGL03vK0Wm-InN4Va6rmnLRHiGJcnkDpsdBul4l1Vd4hrWvnZN+nluSl4JO0mCJVePrDEftwOig2lVY5uMYnmbF3qsMPKQ0rv35U7YWOHcsapzjwKWarLmTg3Pol0CtsS+x2x5CwQhxfZBvzNORsbKGgYKb6NnSbEYcuw2mHM+S+yxtjqGvixUyj9n7tm2BsOgh1TDUVoqcMKflLh0F0EgiO5gPDtwgX1OsDZoKJRgY5beQC97nB2IfEisMEFPmtEmM+LpuQEKyrNQaUslpkIqnAtkiDkGeXch+f+Dh9pI25DdWG11o5GW7hrV6WBPq8K3rDfaCx+TnFOJyXQWZxE-lfOIgwMi27sJxpLRRqjQbiJzNsQ6eY3QrH9mIiRV0HBPmwRcSw5jmiwSggkdAb1rEsyhs4R0-JqKcHWBmeGJFVBsiRqoZQeC-DUTkSrWOpBUDUBCQgfy4TIZRJiRHC0NxXLWlZNE9GtEHB50CP4IAA */
      predictableActionArguments: true,
      id: "ALPHA_O",
      initial: "START",
      context: {
        service,
        projectService: projectsService,
        domainToUnpublish: "subdomain",
      },
      states: {
        START: {
          entry: assign(() => {
            const hasUserData = userService.hasUserData();
            const hasActiveTemaplate = templatesService.getActiveProject();
            const isTemaplate = !hasUserData || hasActiveTemaplate;

            return {
              projectService: isTemaplate ? templatesService : projectsService,
            };
          }),
          always: [
            {
              target: "INIT",
            },
          ],
        },

        INIT: {
          invoke: {
            src: "initEditor",
            onDone: {
              target: "MOUNT_BLOCKS",
            },
          },
        },

        MOUNT_BLOCKS: {
          invoke: {
            src: "mountBlocks",
            onDone: {
              target: "MOUNT_COMPONENTS",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        MOUNT_COMPONENTS: {
          invoke: {
            src: "mountComponents",
            onDone: {
              target: "INIT_ASSET_MANAGER_SERVICE",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        INIT_ASSET_MANAGER_SERVICE: {
          invoke: {
            src: "initAssetManagerService",
            onDone: {
              target: "INIT_STYLE_MANAGER_SERVICE",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        INIT_STYLE_MANAGER_SERVICE: {
          invoke: {
            src: "initStyleManagerService",
            onDone: {
              target: "SEND_PAGES_TO_PAGE_SERVICE",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SEND_PAGES_TO_PAGE_SERVICE: {
          invoke: {
            src: "sendPagesToPageService",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: "notify",
          on: {
            DRAG_START: "DRAG_START",
            DRAG_END: "DRAG_END",
            SHOW_ADD_NEW_ELEMENT_PANEL: "SHOW_ADD_NEW_ELEMENT_PANEL",
            CLOSE_ADD_NEW_ELEMENT_PANEL: "CLOSE_ADD_NEW_ELEMENT_PANEL",
            PUBLISH_PROJECT: "PUBLISH_PROJECT",
            PUBLISH_UPDATES: "PUBLISH_UPDATES",
            UNPUBLISH_PROJECT: "UNPUBLISH_PROJECT",
            DOMAIN_TO_UNPUBLISH: "DOMAIN_TO_UNPUBLISH",
            SAVE_CUSTOM_DOMAIN: "SAVE_CUSTOM_DOMAIN",
            CHANGE_DEVICE_TYPE: "CHANGE_DEVICE_TYPE",
            TOGGLE_PREVIEW: "TOGGLE_PREVIEW",
            UNDO: "UNDO",
            REDO: "REDO",
            TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
            TOGGLE_OUTLINE: "TOGGLE_OUTLINE",
          },
        },

        PUBLISH_UPDATES: {
          invoke: {
            src: "publishUpdates",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        PUBLISH_PROJECT: {
          invoke: {
            src: "publishProject",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DOMAIN_TO_UNPUBLISH: {
          entry: assign({
            domainToUnpublish: (context, event) => event.domainToUnpublish,
          }),
          always: [
            {
              target: "READY",
            },
          ],
        },

        UNPUBLISH_PROJECT: {
          invoke: {
            src: "unpublishProject",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SAVE_CUSTOM_DOMAIN: {
          invoke: {
            src: "saveCustomDomain",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CHANGE_DEVICE_TYPE: {
          invoke: {
            src: "changeDeviceType",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SHOW_ADD_NEW_ELEMENT_PANEL: {
          invoke: {
            src: "showAddNewElementPanel",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CLOSE_ADD_NEW_ELEMENT_PANEL: {
          invoke: {
            src: "hideAddNewElementPanel",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DRAG_END: {
          invoke: {
            src: "dragEnd",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DRAG_START: {
          invoke: {
            src: "dragStart",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },
        TOGGLE_PREVIEW: {
          invoke: {
            src: "togglePreview",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        UNDO: {
          invoke: {
            src: "undo",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        REDO: {
          invoke: {
            src: "redo",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_FULLSCREEN: {
          invoke: {
            src: "toggleFullscreen",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_OUTLINE: {
          invoke: {
            src: "toggleOutline",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        FINISH: {
          type: "final",
        },

        ERROR: {},
        //
      },
    },
    {
      actions: {
        notify: (context) => {
          context.service.notifySubscribers();
        },
      },
      services: {
        publishUpdates: async ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();
          if (!activeProject) {
            console.log("No active project");
            return Promise.resolve();
          }

          const canPurchase = userService.canPurchaseSubscription();
          const editorPages = editor.Pages.getAll();

          const projectPublish: ProjectPublish = {
            projectId: activeProject?.projectId,
            pages: [],
            variables: "",
            components: "",
            scripts: [],
          };

          const history = await projectsService.getHistory(activeProject.projectId);
          const customCode = history.metadata ? history.metadata.customCode : undefined;
          const customHead = history.metadata ? history.metadata.customHead : undefined;
          const title = history.metadata ? history.metadata.name : undefined;
          const description = history.metadata ? history.metadata.description : undefined;

          // gathering pages data

          editorPages.forEach((page, index) => {
            const pageMainComponent = page.getMainComponent();
            const pageHtml = editor.getHtml({ component: pageMainComponent });
            // .replace("%_WINDOW_ORIGIN_%", window.origin);
            const pageCss = editor.getCss({ component: pageMainComponent }) || "";

            const globalVariablesDepth = index !== 0 ? "../" : "./";

            const pagePublishData: PublishPage = {
              frameId: page.getId(),
              name: page.getName(),
              component: "",
              styles: "",
            };

            // js part

            const customTemplate = `
            document.addEventListener("DOMContentLoaded", function() {
              ${customCode}
            });
          `;

            const isCustomCode = customCode !== undefined;

            // HTML part

            const googleIconsLink = projectsService.scripts.find((script) =>
              script.includes("https://fonts.googleapis.com/css2?family=Material+Symbols")
            );
            const toastLink = projectsService.scripts.find((script) =>
              script.includes(TOAST_NOTIFICATIONS_PACKAGE_URL)
            );
            const formOnPage = pageMainComponent.findType("form").length > 0;
            //TODO manipulate html before save
            let projectHead = `
                      <head>
                      <title>${title} | Powered by Codejet.ai</title>
                      <meta name="description" content="${description}">
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                      <link rel="stylesheet" href="${globalVariablesDepth}reset.css" />
                      <link rel="stylesheet" href="./styles.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}variables.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}components.css" />
                      ${googleIconsLink || ""}
                      ${formOnPage ? toastLink : ""}
                      ${customHead || ""}
                      ${canPurchase ? CODEJET_BADGE_SCRIPT : ""}
                      ${customCode ? `<script src="${globalVariablesDepth}index.js"></script>` : ""}
                      </head>`;

            // editor.Canvas.getDocument().head.outerHTML.match(/<head>[\s\S]*<\/head>/)?.[0] || "<head></head>";
            // opening tags for html
            projectHead = "<html>" + projectHead;
            // closing tags for html
            const projectCloseTag = "\n</html>";

            if (customCode) {
              const script: IPageScripts = {
                fileName: "index.js",
                content: customTemplate,
              };
              projectPublish.scripts.push(script);
            }

            // remove class="iframe-no-pointer" from Video component
            const clearedBody = pageHtml.replace(/class="iframe-no-pointer"/g, "");

            pagePublishData.component = projectHead + clearedBody + projectCloseTag;

            // CSS part
            const { variables, styles: stylesAfterRemoveVariables } = extractVariables(pageCss, [
              "local-styles-variables",
              "local-text-styles-variables",
            ]);

            // TODO if we change components we need to update components.css
            const { components, styles: stylesAfterRemoveComponents } = extractComponents(stylesAfterRemoveVariables);

            if (index === 0) {
              projectPublish.variables = variables;
              projectPublish.components = components;
            }
            pagePublishData.styles = stylesAfterRemoveComponents;

            if (formOnPage) {
              pagePublishData.styles += formToastStyle;
            }
            // add page to project
            projectPublish.pages.push(pagePublishData);

            // await projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
            // await projectsService.saveProjectScript(activeProject.projectId, customTemplate || "");
          });

          try {
            const hasCustomDomainAddress = activeProject.customDomain.address !== "";
            const isCustomDomainPublished = activeProject.customDomain.published;
            const shouldPublishCustomDomain = hasCustomDomainAddress && isCustomDomainPublished;
            if (shouldPublishCustomDomain) {
              projectPublish["customDomain"] = activeProject.customDomain.address;
            }
            // push changes to server
            await projectsService.publishProject(projectPublish);
            // update project meta data
            await projectsService.setSynced(activeProject?.projectId, true);
            // invalidate domain
            await projectsService.invalidateDomain(activeProject.projectId);
          } catch (error) {
            console.error(error);
          }

          return Promise.resolve();
        },

        publishProject: async ({ service }, { customDomain }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();
          const activeProject = projectsService.getActiveProject();
          if (!activeProject) {
            console.log("No active project");
            return Promise.resolve();
          }

          const canPurchase = userService.canPurchaseSubscription();
          const editorPages = editor.Pages.getAll();

          const projectPublish: ProjectPublish = {
            projectId: activeProject?.projectId,
            pages: [],
            variables: "",
            components: "",
            scripts: [],
          };

          const history = await projectsService.getHistory(activeProject.projectId);
          const customCode = history.metadata ? history.metadata.customCode : undefined;
          const customHead = history.metadata ? history.metadata.customHead : undefined;
          const title = history.metadata ? history.metadata.name : undefined;
          const description = history.metadata ? history.metadata.description : undefined;

          // gathering pages data

          editorPages.forEach((page, index) => {
            const isCustomCode = customCode !== undefined;
            const pageMainComponent = page.getMainComponent();
            const pageHtml = editor.getHtml({ component: pageMainComponent });
            // .replace("%_WINDOW_ORIGIN_%", window.origin);
            const pageCss = editor.getCss({ component: pageMainComponent }) || "";

            const globalVariablesDepth = index !== 0 ? "../" : "./";

            const pagePublishData: PublishPage = {
              frameId: page.getId(),
              name: page.getName(),
              component: "",
              styles: "",
            };

            // HTML part
            const googleIconsLink = projectsService.scripts.find((script) =>
              script.includes("https://fonts.googleapis.com/css2?family=Material+Symbols")
            );
            const toastLink = projectsService.scripts.find((script) =>
              script.includes(TOAST_NOTIFICATIONS_PACKAGE_URL)
            );
            const formOnPage = pageMainComponent.findType("form").length > 0;
            //TODO manipulate html before save
            let projectHead = `
                      <head>
                      <title>${title} | Powered by Codejet.ai</title>
                      <meta name="description" content="${description}">
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                      <link rel="stylesheet" href="${globalVariablesDepth}reset.css" />
                      <link rel="stylesheet" href="./styles.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}variables.css" />
                      <link rel="stylesheet" href="${globalVariablesDepth}components.css" />
                      ${googleIconsLink || ""}
                      ${formOnPage ? toastLink : ""}
                      ${customHead || ""}
                      ${canPurchase ? CODEJET_BADGE_SCRIPT : ""}
                      ${customCode ? `<script src="${globalVariablesDepth}index.js"></script>` : ""}
                      </head>`;
            // editor.Canvas.getDocument().head.outerHTML.match(/<head>[\s\S]*<\/head>/)?.[0] || "<head></head>";
            // opening tags for html
            projectHead = "<html>" + projectHead;
            // closing tags for html
            const projectCloseTag = "\n</html>";

            const customTemplate = `
                        document.addEventListener("DOMContentLoaded", function() {
                          ${customCode}
                        });
                      `;

            if (customCode) {
              const script: IPageScripts = {
                fileName: "index.js",
                content: customTemplate,
              };
              projectPublish.scripts.push(script);
            }

            pagePublishData.component = projectHead + pageHtml + projectCloseTag;

            // remove class="iframe-no-pointer" from Video component
            const clearedBody = pageHtml.replace(/class="iframe-no-pointer"/g, "");

            pagePublishData.component = projectHead + clearedBody + projectCloseTag;

            // CSS part
            const { variables, styles: stylesAfterRemoveVariables } = extractVariables(pageCss, [
              "local-styles-variables",
              "local-text-styles-variables",
            ]);

            const { components, styles: stylesAfterRemoveComponents } = extractComponents(stylesAfterRemoveVariables);

            if (index === 0) {
              projectPublish.variables = variables;
              projectPublish.components = components;
            }

            pagePublishData.styles = stylesAfterRemoveComponents;

            if (formOnPage) {
              pagePublishData.styles += formToastStyle;
            }

            // add page to project
            projectPublish.pages.push(pagePublishData);

            // await projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
            // await projectsService.saveProjectScript(activeProject.projectId, customTemplate || "");
          });

          try {
            if (customDomain) {
              projectPublish["customDomain"] = activeProject.customDomain.address;
            }

            await projectsService.publishProject(projectPublish);
            await projectsService.setSynced(activeProject?.projectId, true);
            modalMachineInstance.send("CHANGE_VIEW", { viewId: "domains" });
          } catch (error) {
            console.error(error);
          }

          return Promise.resolve();
        },

        unpublishProject: async ({ service, domainToUnpublish }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();

          if (activeProject) {
            try {
              await projectsService.invalidateDomain(activeProject.projectId);
            } catch (e) {
              console.error(e);
            }
            try {
              let projectData;
              if (domainToUnpublish === "customDomain") {
                projectData = {
                  projectId: activeProject.projectId,
                  customDomain: activeProject.customDomain.address,
                };
              } else {
                projectData = {
                  projectId: activeProject.projectId,
                };
              }

              await projectsService.unpublishProject(projectData);
              modalMachineInstance.send("CHANGE_VIEW", { viewId: "domains" });
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log("No active project");
          }

          return Promise.resolve();
        },

        saveCustomDomain: async ({ service }, { customDomain }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();

          if (activeProject) {
            await projectsService.updateProjectData(activeProject.projectId, { customDomain });
          } else {
            console.log("No active project");
          }

          return Promise.resolve();
        },

        sendPagesToPageService: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const pages = editor.Pages.getAll();
          //Editor has be initialised to send pages to pagesMachine
          pagesService.state = pages;
          pagesService.notifySubscribers();

          return Promise.resolve();
        },

        showAddNewElementPanel: ({ service }) => {
          service.addNewElementPanelVisible = true;
          //
          // return Promise.resolve();
          return Promise.resolve();
        },

        hideAddNewElementPanel: ({ service }) => {
          service.addNewElementPanelVisible = false;

          return Promise.resolve();
        },

        // saveProject: async ({ service }) => {
        //TODO this is to save project as HTML and CSS when publish
        // const editor = service.getEditor();
        // if (!editor) return Promise.resolve();
        //
        // const activeProject = projectsService.getActiveProject();
        //
        // if (activeProject) {
        //   activeProject.html = activeProject.projectHead + editor.getHtml() + activeProject.projectCloseTag;
        //   projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
        //   projectsService.saveProjectCss(activeProject.projectId, editor.getCss() || "");
        // } else {
        //   console.log("No active project");
        // }

        // return Promise.resolve();
        // },

        initEditor: (context) => {
          const editor = grapesjs.init(editorConfig);
          context.service.setEditor(editor);
          // DO NOT REMOVE THAT, ITS NEED FOR STOP USER TO BREAK PAGE WHEN PROJECT IS LOADING INTO EDITOR
          editor.UndoManager.stop();

          triggerService.resetTriggersMap();

          // next 2 methods is for stoping run store method when use preview button
          editor.on("run:preview", () => {
            context.service.setPreviewCommandRun(true);
          });

          editor.on("stop:preview", () => {
            context.service.setPreviewCommandRun(true);
          });

          editor.on("change:canvasOffset", () => {
            if (editor.Commands.isActive("preview")) {
              editor.stopCommand("preview");
              editor.runCommand("preview");
            }
          });

          editor.on("block:custom", (props) => {
            service.setBlocksModel(props);
          });

          editor.on("block:drag:start", () => {
            service.setIsBlockBeingDragged(true);
          });

          editor.on("block:drag:stop", () => {
            service.setIsBlockBeingDragged(false);
          });

          editor.on("component:create", (component: Component) => {
            const componentType = component.attributes?.type;
            if (componentType === "icon") {
              createIconStylesRule(component, editor);
            }

            if (componentType === "form") {
              addToastScriptToEditor(editor, context.projectService);
            }

            if (!userService.hasUserData() && editor.UndoManager.hasUndo()) {
              triggerService.setTrigger("addComponents");
            }

            if (component.attributes.type === "smartNavbar" || component.attributes.type === "form") {
              if (editor.UndoManager.hasUndo()) {
                modalMachineInstance.send("OPEN", { modalId: "smart-component-modal" });
              }
            }
          });

          editor.on("component:add", (component) => {
            const { type } = component.attributes;
            // need to select smartNavbar after copy on other page
            if (type === "smartNavbar") {
              editor.select(component);
            }
          });

          editor.on("component:selected", (component: Component) => {
            // puting mange smart navbar placement here because it needs to be called when component is selected, all action take place in this method
            // and after undo smartnavbar is removed correctly
            manageSmartNavbarPlacement(editor, component);
          });

          editor.on("component:clone", (component: Component) => {
            service.setIsBlockBeingDragged(true); // INFO - A weird method that is supposed to restore the ability to copy the image component, it works.
          });

          editor.on("component:deselected", (component: Component) => {
            const { type } = component.attributes;
            // it needed for copy element from one page to another
            if (type === "smartNavbar") {
              component.set("draggable", true);
            }
          });

          editor.on("component:update", (props) => {
            // when create new component a lot of "component:update" is triggered, we want omit that
            // change text trigger: remove -> change content, we want to trigger changeContent instead of remove
            if (!userService.hasUserData() && !triggerService.omitChangeTrigger() && editor.UndoManager.hasUndo()) {
              triggerService.setTrigger("changeContent");
            }

            if (props.materialSymbolType !== undefined) {
              const doc = editor.Canvas.getDocument();
              const head = doc.head;

              const formatType = (type: string) => {
                return type.charAt(0).toUpperCase() + type.slice(1);
              };

              const existingLink = head.querySelector('link[href*="Material+Symbols"]');
              if (existingLink) {
                const newHREF = `https://fonts.googleapis.com/css2?family=Material+Symbols+${formatType(
                  props.materialSymbolType
                )}:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200`;
                existingLink.parentNode?.removeChild(existingLink);

                const link = doc.createElement("link");
                link.rel = "stylesheet";
                link.href = newHREF;
                head.appendChild(link);

                const id = context.projectService.scripts.findIndex((script) => script.includes("Material+Symbols"));

                if (id !== undefined && typeof id === "number") {
                  context.projectService.scripts[id] = link.outerHTML;
                } else {
                  context.projectService.scripts.push(link.outerHTML);
                }
              }
            }
          });

          editor.on("component:remove", (component: Component) => {
            // when we init editor we remove page from editor, we dont want to trigger deleteComponents then
            if (!userService.hasUserData() && editor.UndoManager.hasUndo()) {
              triggerService.setTrigger("deleteComponents");
            }

            const componentType = component.attributes?.type;
            const formCount = editor.DomComponents.getWrapper()?.findType("form").length;
            if (componentType === "form" && formCount === 0) {
              context.projectService.scripts = context.projectService.scripts.filter(
                (script) =>
                  !script.includes(
                    `<script src='${TOAST_NOTIFICATIONS_PACKAGE_URL}' type='text/javascript' deffer></script>`
                  )
              );
            }
          });

          editor.on("layer:custom", () => {
            const layerManagerContainer = document.getElementById("gjs-layer-manager");
            if (layerManagerContainer) {
              layerManagerContainer.appendChild(editor.LayerManager.render());
            }
          });

          editor.on("load", async () => {
            const activeProject = context.projectService.getActiveProject();
            if (activeProject !== undefined) {
              try {
                await loadProject(editor, activeProject, context.projectService);
              } catch (e) {
                // console.error("[:load] - ", e);
                await fallbackLoadProject(editor, activeProject, context.projectService);
              } finally {
                context.service.setIsLoaded(true);
                const pages = editor.Pages.getAll();
                pagesService.state = pages;

                if (context.projectService === templatesService) {
                  modalMachineInstance.send("OPEN", { modalId: "template-welcome-toast" });
                }

                // DO NOT REMOVE THAT, ITS NEED FOR REGISTER CHANGES
                editor.UndoManager.start();
              }
            }

            preventDefaultZoom(editor);

            service.notifySubscribers();
          });

          const initPageScripts = async () => {
            const head = editor.Canvas.getDocument()?.head;
            const canAddCustomHead = head && head?.innerHTML === "";
            if (!canAddCustomHead) return;
            try {
              const activeProject = context.projectService.getActiveProject();
              if (activeProject) {
                addScriptsToEditor(editor, context.projectService);
                await handleCustomCode(editor, activeProject, context.projectService);
              } else {
                console.error("[canvas:frame:load] - No active project");
              }
            } catch (e) {
              console.error("[canvas:frame:load] - ", e);
            }
          };

          editor.on("canvas:frame:load", async () => {
            await initPageScripts();
          });

          editor.on("page:select", async () => {
            await initPageScripts();
          });

          //TODO move to plugin and create function to inject projectService
          editor.Storage.add("remote", {
            async load() {
              // throw new Error('Machine')
              //get active project
              const activeProject = context.projectService.getActiveProject();
              if (activeProject) {
                // remove other projects from local storage

                const projectKey = "project-config-" + activeProject.projectId;

                for (let i = 0; i < sessionStorage.length; i++) {
                  // Get the key name at index i
                  const key = sessionStorage.key(i);

                  if (!key) continue;

                  if (key.startsWith("project-config-") && !key.startsWith(projectKey)) {
                    sessionStorage.removeItem(key);
                  }
                }

                //first load from local storage
                //TODO check how to delete all data from local storage when project is deleted or user logs out
                const localData = sessionStorage.getItem(projectKey);

                if (localData) {
                  const data = JSON.parse(localData);
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  context.projectService.scripts = data.scripts;
                  return data.config;
                }

                // if no local storage load from history
                // if backend dont find hostory return object with config as {}, and metadata from project its needed for adding scripts later on
                const history = await context.projectService.getHistory(activeProject?.projectId);
                if (Object.keys(history.config).length === 0) {
                  throw new Error("No config in history");
                }

                if (history) {
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  context.projectService.scripts = history.scripts;
                  return history.config;
                }
                throw new Error("No history found");
              }
            },

            async store(data) {
              // added to prevent run store before editor is fully loaded
              const editorService = context.service;
              if (!editorService.editorIsLoaded) {
                return Promise.resolve;
              }

              // added to prevent run store method when use preview button in editor
              if (context.service.previewCommandRun) {
                context.service.setPreviewCommandRun(false);
                return Promise.resolve();
              }
              //get active project ID and save project JSON
              //TODO locally store project JSON
              //TODO send only patch on JSON
              //this is a bit tricky but it's only way to use autosave feature

              const activeProject = context.projectService.getActiveProject();

              //get all scripts from editor head as strings
              // const scripts = Array.from(editor.Canvas.getDocument().head.children).map((script) => script.outerHTML);

              const scripts = context.projectService.scripts;
              if (activeProject) {
                // console.log("saving to session storage");
                //save to local storage
                sessionStorage.setItem(
                  "project-config-" + activeProject?.projectId,
                  JSON.stringify({
                    scripts,
                    config: data,
                  })
                );

                // omit initial save, where we create project from history
                if (editor.UndoManager.hasUndo() && !userService.hasUserData()) {
                  triggerService.onStoreChange();
                }

                //if history not empty load history
                if (context.projectService === projectsService) {
                  const savedHistory = await projectsService.saveHistory(activeProject?.projectId, {
                    config: data,
                    scripts,
                  });
                  projectsService.setLastUpdate(savedHistory.createdAt);
                  // editor.UndoManager.hasUndo() is connected with editor.on("load", method
                  // where in "finally" we start UndoManager so only after full render we can set synced to false
                  if (activeProject.isSynced !== false && editor.UndoManager.hasUndo()) {
                    await projectsService.setSynced(activeProject?.projectId, false);
                  }
                }
              }
            },
          });

          return Promise.resolve();
        },

        initAssetManagerService: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.on("asset:custom", (props) => {
            assetManagerService.setAssetManager(props);
            assetManagerInstance.send("INIT");
          });

          editor.on("component:mount", (component) => {
            const layerName = component.get("layername") || component.getName();
            editor.LayerManager.setName(component, layerName);
          });

          editor.on("component:selected", (component) => {
            if (service.invalidImageFlag) {
              component.remove();
              service.setInvalidImageFlag(false);
            }
          });

          editor.on("component:add", (props) => {
            const { tagName, src, attributes, type } = props.attributes;
            const isNewImageAdded =
              tagName === "img" && (src?.startsWith("data:image") || attributes?.src?.startsWith("data:image"));
            // flag isDragging is set to true when grapesjs block is dragged over the whole editor
            const isBlockBeingDragged = service.isBlockBeingDragged;
            if (!isBlockBeingDragged && type === "image") {
              service.setInvalidImageFlag(true);
              editor.select(props);
              return;
            }

            // data:image is placeholder from grapes and indicate that image dropped from BlocksMenu not from Redo/Undo
            if (isNewImageAdded) {
              assetManagerService.selectComponent(props);
              assetManagerInstance.send("UPLOAD", { component: props });
            }
          });

          let handlerReference: (() => void) | null = null;

          editor.on("component:selected", (props) => {
            if (props.attributes.tagName === "img" || props.attributes.type === "codejetVideo") {
              const dblClickHandler = () => {
                const eventName = props.attributes.type === "codejetVideo" ? "UPLOAD_VIDEO" : "UPLOAD";
                assetManagerInstance.send(eventName, { component: props });
              };
              handlerReference = dblClickHandler;

              props.view.el.addEventListener("dblclick", dblClickHandler);

              assetManagerInstance.send("SELECT_COMPONENT", { component: props });
            }
          });

          editor.on("component:deselected", (props) => {
            if (props.attributes.tagName === "img" || props.attributes.tagName === "video") {
              if (handlerReference) {
                props.view.el.removeEventListener("dblclick", handlerReference);
                handlerReference = null;
              }
              assetManagerInstance.send("DESELECT_COMPONENT");
            }
          });

          return Promise.resolve();
        },

        initStyleManagerService: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          stylesManagerInstance.send("INIT");

          // update compoennt on change target
          editor.on("component:selected", (component: Component) => {
            const editor = service.getEditor();
            if (!editor) {
              stylesManagerInstance.send("SELECT_COMPONENT", { component, sectors: undefined });
            } else {
              stylesManagerInstance.send("SELECT_COMPONENT", {
                component,
                sectors: editor.StyleManager.getSectors(),
              });
            }
          });
          editor.on("component:remove", (component) => {
            const selectedComponent = editor.getSelected();

            if (selectedComponent?.getId() === component.getId()) {
              stylesManagerInstance.send("DESELECT_COMPONENT");
            }
          });
          editor.on("style:property:update", (props) => {
            // notify subscribers after style update TODO move to service/machine
            styleManagerService.notifySubscribers();
          });
          editor.on("style:property:remove", (props) => {
            // notify subscribers after style update TODO move to service/machine
            styleManagerService.notifySubscribers();
          });
          editor.on("style:property:add", (props) => {
            // notify subscribers after style update TODO move to service/machine
            styleManagerService.notifySubscribers();
          });

          editor.on("selector:custom", (props) => {
            selectorManagerInstance.setSelectorManager(editor.Selectors);
            selectorManagerInstance.open();
          });
          editor.on("component:selected", (props) => {
            selectorManagerInstance.selectComponent(props);
          });
          editor.on("component:deselected", (props) => {
            selectorManagerInstance.deselectComponent();
          });

          return Promise.resolve();
        },

        mountComponents: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          for (const componentDef of service.componentsDef) {
            editor.Components.addType(componentDef.id, componentDef.props);
          }

          return Promise.resolve();
        },

        mountBlocks: async ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          try {
            const blacklistComponents: string[] = await getBlacklistedComponents();
            //update blocks according to blacklisted in feature flag so will be available only on dev for example
            service.blocks = service.blocks.filter((block) => !blacklistComponents.includes(String(block.id)));
          } catch (e) {
            //TODO more elegant way to handle this
            console.log(e);
          }

          //mount block from service to editor
          for (const block of service.blocks) {
            if (block.id) {
              editor.Blocks.add(block.id, block);
            }
          }

          //Load blocks added from plugins to service
          const blockMap = editor.Blocks.getAllMap();
          for (const key in blockMap) {
            if (service.blocks.find((block) => block.id === key)) continue;
            service.blocks.push(blockMap[key].attributes as BlockProperties);
          }

          return Promise.resolve();
        },

        //appendService
        dragEnd: ({ service }, { id }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const block = service.blocksModel.blocks.find((block) => block.id === id);
          if (block) {
            service.blocksModel.dragStop(block);
          }
          return Promise.resolve();
        },

        dragStart: ({ service }, { id }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const block = service.blocksModel.blocks.find((block) => block.id === id);
          if (block) {
            service.blocksModel.dragStart(block);
          }
          return Promise.resolve();
        },

        changeDeviceType: ({ service }, { deviceType }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          // its a fallback for change:canvasOffset because it's not trigger sometimes
          setTimeout(() => {
            editor.stopCommand("preview");
            editor.runCommand("preview");
          }, 400);

          editor.setDevice(deviceType);
          return Promise.resolve();
        },

        togglePreview: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("preview")) {
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: true }));
            // add pointer events none to video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper > *");
            iframes?.forEach((iframe) => {
              iframe.addClass("iframe-no-pointer");
            });
            editor.stopCommand("preview");
          } else {
            // remove pointer events from video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper > *");
            iframes?.forEach((iframe) => {
              iframe.removeClass("iframe-no-pointer");
            });

            editor.stopCommand("sw-visibility");
            editor.runCommand("preview");
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: false }));
          }

          return Promise.resolve();
        },

        undo: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.UndoManager.undo();

          return Promise.resolve();
        },

        redo: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.UndoManager.redo();

          return Promise.resolve();
        },

        toggleFullscreen: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("fullscreen")) {
            editor.stopCommand("fullscreen");
          } else {
            editor.runCommand("fullscreen", { target: ".alpha-o" });
          }

          return Promise.resolve();
        },

        toggleOutline: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("sw-visibility")) {
            editor.stopCommand("sw-visibility");
          } else {
            editor.runCommand("sw-visibility");
          }

          return Promise.resolve();
        },
      },

      guards: {
        //appendGuards
      },
    }
  );
