// import { FacebookLogo } from "../../../../../../assets/svg/facebook_logo";

import type { TSignInSection } from "./SignInSection.types";

import "./SignInSection.css";
import { SignUpCta } from "../SignUpCta/SignUpCta";

export const SignInSection: TSignInSection = () => {
  return (
    <>
      <div className="sign-in__header">
        <h2 className="sign-in__typography">Create an account</h2>
        <h3 className="sign-in__typography">You need to have an account to make a purchase.</h3>
      </div>
      <SignUpCta />
      <div className="sign-in__login-container">
        <div className="codejet__typography sign-in__login-desc">
          By creating an account, you agree to receive Codejet emails, including marketing emails, and to our Terms of
          Service & Privacy Policy
        </div>
      </div>
    </>
  );
};
