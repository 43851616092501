export type TRgbaColorArray = [number, number, number, number];

const extractColorValues = (color: string): TRgbaColorArray => {
  const regex = /rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/;
  const matches = color.match(regex);
  if (matches) {
    const [, r, g, b, a] = matches;
    return [parseInt(r), parseInt(g), parseInt(b), parseFloat(a)];
  }
  return [0, 0, 0, 0];
};

const blendTwoColors = (
  color1: TRgbaColorArray,
  color2: TRgbaColorArray,
): TRgbaColorArray => {
  // Decompose each color into red, green, blue, and alpha components
  const [r1, g1, b1, a1] = color1;
  const [r2, g2, b2, a2] = color2;

  // Calculate the mixed alpha value
  const mixedAlpha = a1 + a2 * (1 - a1);

  // Return a transparent color for fully transparent alpha
  if (mixedAlpha === 0) {
    return [0, 0, 0, 0];
  }

  // Calculate and normalize mixed color components
  const mixedR = (r1 * a1 + r2 * a2 * (1 - a1)) / mixedAlpha;
  const mixedG = (g1 * a1 + g2 * a2 * (1 - a1)) / mixedAlpha;
  const mixedB = (b1 * a1 + b2 * a2 * (1 - a1)) / mixedAlpha;
  return [mixedR, mixedG, mixedB, mixedAlpha];
};

/**
 * Mixes an array of RGBA color strings and returns the resulting color as an RGBA string.
 *
 * @param colors - An array of color strings in the format 'rgba(r, g, b, a)'.
 * **Note:** The colors are mixed in the order they are provided.
 * **Note:** The first color in the array is at the bottom.
 * @returns The resulting mixed color as an RGBA string.
 *
 * @example
 * ```typescript
 * const colors = ['rgba(255, 0, 0, 0.5)', 'rgba(0, 0, 255, 0.5)'];
 * const mixedColor = mixColors(colors);
 * console.info(mixedColor); // 'rgba(128, 0, 128, 0.5)'
 * ```
 */
export const mixColors = (colors: string[]): string => {
  const [firstColor, ...restColors] = colors.map((color) =>
    extractColorValues(color),
  );
  const [mixedR, mixedG, mixedB, mixedA] = restColors.reduce((acc, color) => {
    return blendTwoColors(acc, color);
  }, firstColor);
  // parseFloat remove trailing zeros
  return `rgba(${mixedR.toFixed()}, ${mixedG.toFixed()}, ${mixedB.toFixed()}, ${parseFloat(
    mixedA.toFixed(2),
  )})`;
};
