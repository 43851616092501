import { useState, useEffect, useMemo } from "react";
// import dayjs from "dayjs";fire

import { PaymentsService } from "../../../services/Payments/Payments";
import { UserService } from "../../../services/UserService/UserService";
import { projectsInstance, projectsService } from "../../../machines/projects/projects.instance";

import { ButtonNew } from "../../atoms/ButtonNew";
import { Plan } from "../../Dashboard/molecules/Plan";
import { Starter } from "../../../assets/svg/Starter";
import { EssentialPlan } from "../../../assets/svg/EssentialPlan";
import { ProPlan } from "../../../assets/svg/ProPlan";
import { CreditCard } from "../../../assets/svg/CreditCard";
import { GridMiniIcon } from "../../../../assets/svg/grid-mini-icon";
import { LikeMessage } from "../../../../assets/svg/like_message";
import { Shield } from "../../../../assets/svg/shield";
import { Button } from "../../Dashboard/atoms/Button";
import { ArrowUpRight } from "../../../assets/svg/ArrowUpRight";

import { useAsyncAction } from "../../../utils/useAsyncAction/useAsyncAction";
import { formatPeriodDate } from "../../../utils/formatPeriodDate/formatPeriodDate";
import { getSubscriptionsDetailsByType } from "../../../utils/getSubscriptionsDetails/getSubscriptionsDetails";
import { getPublishedDomainAddress } from "../../../helpers/getPublishedDomainAddress";

import { TPlanSettings } from "./PlanSettings.types";
import { type Project } from "../../../types/types";

import "./PlanSettings.css";

import { SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPE } from "@shared/constants/subscription";

const userService = UserService.getInstance();

const lockedStatuses = ["incomplete", "incomplete_expired", "past_due", "unpaid"];

export const PlanSettings: TPlanSettings = () => {
  const [activeProject, setActiveProject] = useState<Project>();
  const [_, setState] = useState(userService.state);

  const essentialPlanPriceID = import.meta.env.VITE_SITE_PLAN_PERSONAL_ID;
  const proPlanPriceID = import.meta.env.VITE_SITE_PLAN_BUSINESS_ID;
  // const { priceId = "", status = SubscriptionStatus.active, nextPlanPriceId } = activeProject?.subscription || {};
  const { priceId, status, nextPlanPriceId } = getSubscriptionsDetailsByType(activeProject, SUBSCRIPTION_TYPE.site_sub);

  const isStarter = priceId === "";
  const isEssential = priceId === essentialPlanPriceID;
  const isPro = priceId === proPlanPriceID;
  const projectDomainAddress = useMemo(() => getPublishedDomainAddress(activeProject), [activeProject]);

  // TODO Lukasz change logic when backend is ready
  const { isLoading, handleAsyncAction } = useAsyncAction();

  useEffect(() => {
    const project = projectsInstance.getSnapshot().context.service.getActiveProject();
    setActiveProject(project);
  }, []);

  useEffect(() => {
    userService.subscribe(setState);
    return () => userService.unsubscribe(setState);
  }, []);

  const subscriptionTerminateDate = (priceIdToCheck: string, subscriptions: Project["subscriptions"] | undefined) => {
    if (!subscriptions || !subscriptions.length) return "";
    const { priceId, isClosed, currentPeriodEnd } = getSubscriptionsDetailsByType(activeProject, SUBSCRIPTION_TYPE.site_sub);
    if (priceIdToCheck !== priceId || !isClosed) {
      return "";
    }

    return formatPeriodDate(currentPeriodEnd || 0);
  };
  const newSubscriptionStartDate = (priceIdToCheck: string, subscriptions: Project["subscriptions"] | undefined) => {
    if (!subscriptions || !subscriptions.length) return "";
    const { isClosed, currentPeriodEnd } = getSubscriptionsDetailsByType(activeProject, SUBSCRIPTION_TYPE.site_sub);
    if (priceIdToCheck !== nextPlanPriceId || !isClosed) {
      return "";
    }

    return formatPeriodDate(currentPeriodEnd || 0);
  };

  const handleEssentialPlanButton = () => {
    if (isPro) {
      return async () =>
        await handleAsyncAction(() =>
          projectsService.downgradeProjectSubscription(activeProject?.projectId || "", essentialPlanPriceID, SUBSCRIPTION_TYPE.site_sub)
        );
    }

    if ((isEssential && status === SUBSCRIPTION_STATUS.active) || nextPlanPriceId === essentialPlanPriceID) {
      return undefined;
    }

    if (isEssential && lockedStatuses.includes(status)) {
      async () => await PaymentsService.createPortalSession();
    }

    return async () =>
      await PaymentsService.createCheckoutSession(
        //TODO In the new flow, at this point, the products should be collected in the following structure: [{ priceId: string, type: "oneOffProduct" | "subscription" }, ...]
        [{ priceId: essentialPlanPriceID, type: "subscription" }],
        activeProject?.projectId || ""
      );
  };

  const planData = [
    {
      title: "Free",
      icon: <Starter />,
      price: "$0",
      frequency: "/forever",
      description: "Simple, static sites",
      features: [
        "<b>Codejet.site domain</b>",
        "<b>Codejet banner</b>",
        "<b>2</b> website pages",
        "<b>100</b> form submission<br/>(lifetime)",
        "<b>1,000</b> visitors/mo",
      ],
      actionButtonLabel: isStarter ? "Current plan" : "Downgrade to Free",
      closeDate: subscriptionTerminateDate("", activeProject?.subscriptions),
      startDate: newSubscriptionStartDate("", activeProject?.subscriptions),
      actionButtonOnClick: isStarter
        ? undefined
        : async () =>
            await handleAsyncAction(() => {
              return projectsService.downgradeProjectSubscription(activeProject?.projectId || "", "", SUBSCRIPTION_TYPE.site_sub);
            }),
      actionCurrentPlan: isStarter,
      actionButtonLoading: isLoading,
    },
    {
      title: "Personal",
      icon: <EssentialPlan />,
      price: "$7.99",
      frequency: "/per month",
      description: "Portfolios or small business websites",
      features: [
        "<b>Custom domain</b>",
        "<b>50</b> website pages",
        "<b>500</b> form submissions (monthly)",
        "<b>10,000</b> visitors (monthly)",
        {
          icon: <LikeMessage />,
          label: "<b>Premium support</b>",
        },
        {
          icon: <Shield />,
          label: "<b>14-day</b> money back guarantee",
        },
      ],
      closeDate: subscriptionTerminateDate(essentialPlanPriceID, activeProject?.subscriptions),
      startDate: newSubscriptionStartDate(essentialPlanPriceID, activeProject?.subscriptions),
      actionButtonLabel: isEssential ? "Current plan" : isPro ? "Downgrade to Personal" : "Upgrade to Personal",
      actionButtonOnClick: handleEssentialPlanButton(),
      actionCurrentPlan: isEssential,
      actionButtonLoading: isLoading,
    },
    {
      title: "Business",
      icon: <ProPlan />,
      price: "$11.99",
      frequency: "/per month",
      description: "Companies ready to scale their online presence",
      features: [
        "<b>Custom domain</b>",
        "<b>100</b> website pages",
        "<b>Unlimited</b> form submission",
        "<b>100,000</b> visitors (monthly)",
        {
          icon: <LikeMessage />,
          label: "<b>Premium support</b>",
        },
        {
          icon: <Shield />,
          label: "<b>14-day</b> money back guarantee",
        },
      ],
      actionButtonLabel: isPro ? "Current plan" : "Upgrade to Business",
      closeDate: subscriptionTerminateDate(proPlanPriceID, activeProject?.subscriptions),
      startDate: newSubscriptionStartDate(proPlanPriceID, activeProject?.subscriptions),
      actionButtonOnClick:
        isPro && status === SUBSCRIPTION_STATUS.active
          ? undefined
          : async () =>
              await PaymentsService.createCheckoutSession(
                //TODO In the new flow, at this point, the products should be collected in the following structure: [{ priceId: string, type: "oneOffProduct" | "subscription" }, ...]
                [{ priceId: proPlanPriceID, type: "subscription" }],
                activeProject?.projectId || ""
              ),
      actionCurrentPlan: isPro,
      actionButtonLoading: isLoading,
    },
  ];

  return (
    <>
      <div className="plan-settings__content container">
        <header className="plan-settings__heading">
          <div className="plan-settings__grid">
            <div className="plan-settings__typography">
              <a className="project-settings__link" href={projectDomainAddress ?? "#"}>
                {activeProject?.name}
                {projectDomainAddress ? <ArrowUpRight /> : null}
              </a>

              <h2 className="plan-settings__title">Site plan</h2>
              <p className="plan-settings__subtitle">
                You can upgrade your plan at any time to publish to add a custom domain, get more
                <br /> traffic, and unlock additional features to create more powerful, custom websites.
              </p>
            </div>
            <Button
              active
              className="project-settings__header-container-btn"
              onClick={() => {
                projectsInstance.send("SELECT_PROJECT", { projectId: activeProject?.projectId });
              }}
            >
              <GridMiniIcon /> Open website builder
            </Button>
          </div>
          <div className="plan-settings__cta-wrapper">
            {userService?.data?.CUSTOMER_ID && (
              <ButtonNew
                className="plan-settings__cta"
                type="text"
                disabled={isLoading}
                loading={isLoading}
                onClick={async () => handleAsyncAction(PaymentsService.createPortalSession)}
                iconStart={<CreditCard />}
              >
                Edit payment data
              </ButtonNew>
            )}
          </div>
        </header>
        <div className="plan-settings__plans">
          {planData.map((plan) => (
            <Plan key={plan.title} {...plan} />
          ))}
        </div>
      </div>
    </>
  );
};
