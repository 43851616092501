import { type CSSProperties } from "react";

type TToastInfoIcon = {
  className?: string;
  style?: CSSProperties;
};

export const ToastInfoIcon = ({ className, style }: TToastInfoIcon) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.01185 14.4404C11.5622 14.4404 14.4404 11.5622 14.4404 8.01184C14.4404 4.46146 11.5622 1.58332 8.01185 1.58332C4.46148 1.58332 1.58333 4.46146 1.58333 8.01184C1.58333 11.5622 4.46148 14.4404 8.01185 14.4404ZM15.9404 8.01184C15.9404 12.3906 12.3907 15.9404 8.01185 15.9404C3.63305 15.9404 0.0833321 12.3906 0.0833321 8.01184C0.0833321 3.63304 3.63305 0.0833168 8.01185 0.0833168C12.3907 0.0833168 15.9404 3.63304 15.9404 8.01184ZM7.11453 4.75158C7.11453 4.25601 7.51628 3.85427 8.01185 3.85427C8.50742 3.85427 8.90916 4.25601 8.90916 4.75158C8.90916 5.24715 8.50742 5.6489 8.01185 5.6489C7.51628 5.6489 7.11453 5.24715 7.11453 4.75158ZM7.2737 11.5571C7.2737 11.9713 7.60949 12.3071 8.0237 12.3071C8.43792 12.3071 8.7737 11.9713 8.7737 11.5571V7.55705C8.7737 7.14284 8.43792 6.80705 8.0237 6.80705C7.60949 6.80705 7.2737 7.14284 7.2737 7.55705L7.2737 11.5571Z"
      fill="#E5E5EA"
    />
  </svg>
);
