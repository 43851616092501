/**
 * Checks if the current device is a mobile device.
 * @returns {boolean} Returns true if the device is a mobile device, otherwise returns false.
 */
export const isMobile = () => {
  // SSR handling: if there is no `window` or `navigator`, we assume it is not a browser environment.
  if (typeof window === "undefined" || typeof navigator === "undefined") {
    return false;
  }

  const mobileUserAgentRegex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileUserAgentRegex.test(navigator.userAgent);
};
