import { Editor } from "grapesjs";
import { Categories } from "./types";
import { TColorReferences } from "./ColorMode.types";
import { mixColors } from "@shared/utils/mix-rgba";

export const formatLabel = (label: string) => {
  const categories = label.match(/[^_]+(?=_)/g);
  //remove all _ and replace with space
  let formattedLabel = label.replace(/_/g, " ");
  // remove categories
  formattedLabel = formattedLabel.replace("colours", " ");
  categories?.forEach((category) => {
    formattedLabel = formattedLabel.replace(category, "");
  });
  // replace - sign with space
  formattedLabel = formattedLabel.replace(/-/g, " ");
  // remove multiple spaces
  formattedLabel = formattedLabel.replace(/ {2,}/g, " ");
  return formattedLabel;
};

const formatCategory = (label: string) => {
  return label.replace(/_/g, " ").replace(/-/g, " ");
};

const addColorToCategory = (categoryObj: any, keys: string[], currentKey: string, currentValue: string) => {
  if (keys.length === 0) return;
  const currentCategory = formatCategory(keys[0]);
  if (!(currentCategory in categoryObj)) {
    categoryObj[currentCategory] = { colors: [], categories: {} };
  }

  if (keys.length === 1) {
    categoryObj[currentCategory].colors.push({
      colorLabel: formatLabel(currentKey),
      colorName: currentKey,
      colorValue: currentValue,
    });
  } else {
    addColorToCategory(categoryObj[currentCategory].categories, keys.slice(1), currentKey, currentValue);
  }
};

export const getCategoriesFromColors = (colors: Record<string, any>) => {
  return Object?.entries(Object.assign({}, colors)).reduce<Categories>((acc, [currentKey, currentValue]) => {
    // check if value is color
    if (!currentValue.startsWith("rgba(")) return acc;

    let keys = currentKey.match(/[^_]+(?=_)/g);
    // if we don't have keys we need to add default keys
    if (!keys) {
      keys = ["--colours", "without-category"];
    }
    // theme colours start with --colours
    // when some create own category, add default --colours category for consistency
    if (keys[0] !== "--colours") {
      keys.unshift("--colours");
    }

    keys.shift();

    addColorToCategory(acc, keys, currentKey, currentValue);

    return acc;
  }, {});
};

export const calculateUpdatedColors = ({
  colorName,
  colorValue,
  colorReferences,
  editor,
}: {
  colorName: string;
  colorValue: string;
  colorReferences: TColorReferences;
  editor: Editor | null;
}) => {
  if (!editor) return;
  const localColorsToChange = colorReferences[colorName];
  const newColors: Record<string, string> = {};
  Object.keys(localColorsToChange).forEach((key) => {
    const colorEntry = localColorsToChange[key];
    // if --primary-500: var(--colours_brand_primary-500);
    if (typeof colorEntry === "string") {
      newColors[key] = colorValue;
      // if primary-500: ["rgba(0,0,0,0)", "--colours_brand_primary-500"];
    } else if (Array.isArray(colorEntry)) {
      // replace "--colours_brand_primary-500" with colorValue
      const updatedColors = colorEntry.map((color) => (color === colorName ? colorValue : color));
      // if there is only one color, return it
      if (colorEntry.length === 1) {
        return (newColors[key] = colorValue);
      }

      newColors[key] = mixColors(updatedColors);
    }
  });

  return newColors;
};
