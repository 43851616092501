import type { Editor } from "grapesjs";
import { Projects as ProjectsService } from "../../../../../services/Projects/Projects";

import type { Project, ProjectPages } from "../../../../../types/types";
import { IProjectPageFields } from "@shared/types/projects.interfaces";
import { IFigmaPageData } from "@shared/types/figma-data.interface";

/**
 * Loads a project into the editor.
 *
 * @param editor - The editor instance.
 * @param activeProject - The active project to load.
 * @param projectsService - The service for managing projects.
 * @returns A promise that resolves when the project is loaded.
 */
export const loadProject = async (editor: Editor, activeProject: Project, projectsService: ProjectsService) => {
  await editor.load({ projectId: activeProject?.projectId });

  // on templates we don't check if we need to update pages

  // if (activeProject.pages) {
  //   const pagesToUpdate = getPagesToUpdate(activeProject.pages);
  //   if (pagesToUpdate.length !== 0) {
  //     await updatePagesInEditor(editor, activeProject.projectId, pagesToUpdate, projectsService);
  //     projectsService.projectFetched(activeProject.projectId);
  //   }
  // }
};

/**
 * Retrieves the pages that need to be updated from the given project pages.
 *
 * @param pages - The project pages object.
 * @returns An array of key-value pairs representing the pages that need to be updated.
 */
export const getPagesToUpdate = (pages: ProjectPages): [string, IProjectPageFields][] => {
  return Object.entries(pages).filter(([_, page]) => page.needUpdate);
};

/**
 * Updates the pages in the editor based on the provided parameters.
 *
 * @param editor - The editor instance.
 * @param projectId - The ID of the project.
 * @param pagesToUpdate - An array of page updates, where each update is represented by a tuple containing the key and the updated page.
 * @param projectsService - The service used to retrieve OVH pages.
 * @returns A Promise that resolves once the pages have been updated in the editor.
 */
export const updatePagesInEditor = async (
  editor: Editor,
  projectId: string,
  pagesToUpdate: [string, IProjectPageFields][],
  projectsService: ProjectsService
) => {
  const ovhPages: IFigmaPageData[] = await projectsService.getOvhPagesByProjectId(projectId);

  pagesToUpdate.forEach(([key, page]) => {
    const ovhPage = ovhPages.find((ovhPage) => ovhPage.frameId === page.frameId);
    if (!ovhPage) return;

    const isPageInEditor = editor.Pages.get(page.frameId);
    const isMainPage = editor.Pages.getMain().id === page.frameId;
    const ovhPageBody = ovhPage.component.match(/<body>[\s\S]*<\/body>/)?.[0] || "<body></body>";

    if (isPageInEditor) {
      editor.Pages.remove(page.frameId);
      editor.Pages.add({
        id: ovhPage.frameId,
        name: ovhPage.name,
        component: ovhPage.component,
        styles: ovhPage.styles,
        type: true,
      });
    }

    if (isMainPage) {
      editor.Pages.select(ovhPage.frameId);
    }

    if (!isPageInEditor) {
      editor.Pages.add({
        id: page.frameId,
        name: ovhPage.name,
        styles: ovhPage.styles,
        component: ovhPageBody,
      });
    }
  });
};

/**
 * Fallback function for loading a project.
 *
 * @param editor - The editor instance.
 * @param activeProject - The active project.
 * @param projectsService - The projects service.
 * @returns A Promise that resolves when the project is loaded.
 */
export const fallbackLoadProject = async (editor: Editor, activeProject: Project, projectsService: ProjectsService) => {
  const pages: IFigmaPageData[] = await projectsService.getPagesByProjectId(activeProject.projectId);

  // event if in editor.config we don't specify pages, we need to remove the default page because editor add blank page
  if (pages && pages.length !== 0) {
    const defaultPage = editor.Pages.getMain();
    editor.Pages.remove(defaultPage);
  }

  let resetLink = "";
  // let componentsLink = "";

  pages.forEach(({ frameId, name, component, styles }) => {
    const body = component.match(/<body>[\s\S]*<\/body>/)?.[0] || "<body></body>";

    if (resetLink === "") {
      resetLink = component.match(/<link.*?reset.*?>/)?.[0] || "";
    }

    // if (componentsLink === "") {
    //   const componentsRegex = /<link\s+rel="stylesheet"\s+href="(\/api\/project-data\/components[^"]*)"\s*\/?>/;
    //   const componentsMatch = component.match(componentsRegex);
    //   componentsLink = componentsMatch ? componentsMatch[0] : "";
    // }

    editor.Pages.add({
      id: frameId,
      name,
      styles: styles,
      component: body,
    });
  });

  projectsService.scripts.push('<meta name="viewport" content="width=device-width, initial-scale=1.0" />');
  projectsService.scripts.push(resetLink);
  // projectsService.scripts.push(componentsLink);

  const mainPage = editor.Pages.getMain();
  editor.Pages.select(mainPage);

  // don't need that part its say database that project is fetched
  // projectsService.projectFetched(activeProject.projectId);
  editor.UndoManager.clear();
};

/**
 * Adds scripts to the editor.
 *
 * @param editor - The editor instance.
 * @param projectsService - The projects service.
 */
export const addScriptsToEditor = (editor: Editor, projectsService: ProjectsService) => {
  projectsService.scripts.forEach((script: string) => {
    const scriptWithComponent = script.includes("api/project-data/components");
    if (!scriptWithComponent) {
      editor.Canvas.getDocument().head.innerHTML += script;
    }
  });
};

/**
 * Handles custom code for the editor.
 *
 * @param editor - The editor instance.
 * @param activeProject - The active project.
 * @param projectsService - The projects service.
 */
export const handleCustomCode = async (editor: Editor, activeProject: Project, projectsService: ProjectsService) => {
  const history = await projectsService.getHistory(activeProject.projectId);
  const customCode = history.metadata ? history.metadata.customCode : undefined;

  if (customCode !== undefined) {
    const scriptCode = `
        if (document.readyState !== "loading") {
          ${customCode}
        }
      `;

    const scriptTagId = "customScriptTag";
    const script = editor.Canvas.getDocument().getElementById(scriptTagId);
    if (script) {
      script.textContent = scriptCode;
    } else {
      const script = document.createElement("script");
      script.id = scriptTagId;
      script.type = "text/javascript";
      script.textContent = scriptCode;
      editor.Canvas.getDocument().head.appendChild(script);
    }

    if (!activeProject.hasOwnProperty("jsScripts")) {
      activeProject.jsScripts = [];
    }

    activeProject.jsScripts.push(scriptCode);

    const customHead = history.metadata ? history.metadata.customHead : undefined;
    if (customHead) {
      editor.Canvas.getDocument().head.innerHTML += customHead;
    }
  }
};
