import {
  SubscriptionStatusObj,
  SubscriptionTypesObj,
  WebSubscriptionPlanObj,
} from '../types/projects.interfaces';

export const SUBSCRIPTION_TYPE: SubscriptionTypesObj = {
  site_sub: 'site_sub',
  marketing_sub: 'marketing_sub',
} as const;

export const SUBSCRIPTION_STATUS: SubscriptionStatusObj = {
  active: 'active',
  canceled: 'canceled',
  trialing: 'trialing',
  unpaid: 'unpaid',
  past_due: 'past_due',
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  paused: 'paused',
} as const;

export const WEB_SUBSCRIPTION_PLANS: WebSubscriptionPlanObj = {
  starter: 'starter',
  essential: 'essential',
  pro: 'pro',
} as const;
