import { useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { XYCoord } from "dnd-core";

import { DragDrop } from "../../../../../../../../assets/svg/DragDrop";
import { Button } from "../../../../../../../AlphaO/atoms/Button";
import { Trash3 } from "../../../../../../../../assets/svg/Trash3";

import type { DragItem, TListItem } from "./ListItem.types";

import "./ListItem.css";

// After replace text in model, editor replace text to node with text,
// so it's not accessible by model.content

export const ListItem: TListItem = ({ id, index, model, label, removeModel, selectModel, moveModel, syncEditor }) => {
  const ref = useRef<HTMLLIElement>(null);
  const [canDrag, setCanDrag] = useState(false);

  const handleRemoveOption = () => {
    removeModel(model);
  };

  const selectThisLayer = () => {
    selectModel(model);
  };

  const [, drop] = useDrop<DragItem, void>({
    accept: "MENU_LINK",
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveModel(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: "MENU_LINK",
    canDrag: () => canDrag,
    item: () => {
      return { id, index };
    },
    previewOptions: {
      captureDraggingState: true,
    },
    // collect: (monitor: any) => ({
    //   isDragging: monitor.isDragging(),
    // }),
    end: () => {
      // elements are changing when dragging, so we need save position after dragging end
      setCanDrag(false);
      syncEditor();
    },
  });

  drag(drop(ref));

  return (
    <li className="trait-manager__options-list-element" ref={ref}>
      <button
        onMouseDown={() => setCanDrag(true)} // Enable dragging on button press
        onMouseUp={() => setCanDrag(false)} // Optionally disable dragging after release
        className="stack-property__drag"
      >
        <DragDrop />
      </button>
      <button className="trait-manager__options-label" onClick={selectThisLayer}>
        {label ?? model.getInnerHTML()}
      </button>
      <Button
        type="icon"
        iconStart={<Trash3 />}
        onClick={handleRemoveOption}
        className="stack-property__remove-action"
        dataTest={label ? ["remove-form-el", label] : undefined}
      />
    </li>
  );
};
