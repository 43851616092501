import { type CSSProperties } from "react";

type TCancel = {
  className?: string;
  style?: CSSProperties;
};

export const Cancel = ({ className, style }: TCancel) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V11C12 11.5523 11.5523 12 11 12H1C0.447715 12 0 11.5523 0 11V1ZM2.90641 9.09359C2.66233 8.84951 2.66233 8.45379 2.90641 8.20971L5.11609 6.00002L2.90639 3.79031C2.66231 3.54624 2.66231 3.15051 2.90639 2.90643C3.15046 2.66235 3.54619 2.66235 3.79027 2.90643L5.99998 5.11614L8.20971 2.90641C8.45379 2.66233 8.84951 2.66233 9.09359 2.90641C9.33767 3.15049 9.33767 3.54621 9.09359 3.79029L6.88386 6.00002L9.09357 8.20973C9.33765 8.45381 9.33765 8.84954 9.09357 9.09361C8.84949 9.33769 8.45376 9.33769 8.20969 9.09361L5.99998 6.88391L3.79029 9.09359C3.54621 9.33767 3.15049 9.33767 2.90641 9.09359Z"
      fill="#43434A"
    />
  </svg>
);
