import { type SVGProps } from "react";
export const CircleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00939 0.910706C3.64118 0.910706 0.910706 3.64118 0.910706 7.00939C0.910706 10.3776 3.64118 13.1081 7.00939 13.1081C10.3776 13.1081 13.1081 10.3776 13.1081 7.00939C13.1081 3.64118 10.3776 0.910706 7.00939 0.910706ZM2.16071 7.00939C2.16071 4.33154 4.33154 2.16071 7.00939 2.16071C9.68728 2.16071 11.8581 4.33153 11.8581 7.00939C11.8581 9.68729 9.68729 11.8581 7.00939 11.8581C4.33153 11.8581 2.16071 9.68728 2.16071 7.00939ZM10.1762 5.78096C10.4297 5.54666 10.4452 5.15124 10.2109 4.89776C9.97659 4.64429 9.58117 4.62875 9.32769 4.86305L5.81295 8.11198L4.76121 7.04319C4.5191 6.79716 4.12339 6.79398 3.87736 7.03608C3.63132 7.27819 3.62814 7.67391 3.87025 7.91994L5.34688 9.4205C5.58365 9.66112 5.96871 9.67023 6.2166 9.44109L10.1762 5.78096Z"
      fill="currentColor"
    />
  </svg>
);
