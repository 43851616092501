import { type SVGProps } from "react";

export const Checked = (props: SVGProps<SVGSVGElement>) => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="ico" clipPath="url(#checked-circle-path)">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12ZM9.72018 4.8137C9.97365 4.5794 9.98919 4.18397 9.75489 3.9305C9.52058 3.67702 9.12516 3.66148 8.87169 3.89579L5.35694 7.14472L4.3052 6.07593C4.06309 5.82989 3.66738 5.82671 3.42135 6.06882C3.17531 6.31093 3.17213 6.70664 3.41424 6.95267L4.89087 8.45324C5.12764 8.69386 5.5127 8.70297 5.76059 8.47382L9.72018 4.8137Z"
        fill="#F7F7FA"
      />
    </g>
    <defs>
      <clipPath id="checked-circle-path">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
