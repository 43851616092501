import { useState, useEffect } from "react";
import axios from "axios";

import { modalMachineInstance } from "../..";
import { useDebounce } from "../../../../../../../hooks/useDebounce/useDebounce";
import { UserService } from "../../../../../../../services/UserService/UserService";

import { Checked } from "../../../../../../../../assets/svg/checked";

import { ModalHeader } from "../../partials/ModalHeader";
import { ModalFooter } from "../../partials/ModalFooter";

import { Button } from "../../../../../../AlphaO/atoms/Button";

import type { ChangeEvent, MouseEvent } from "react";

import "./AiNotifyModal.css";

const userService = UserService.getInstance();

type SendDataProps = {
  ID?: number;
  CLICKED_GENERATE: boolean;
  IS_LOGGED_IN?: boolean;
  EMAIL?: string;
};

type TSendData = (props: SendDataProps) => Promise<any>;

const sendData: TSendData = async (props) => {
  if (!props.ID) {
    return await axios.post("/api/ai-notify", props);
  } else {
    return await axios.patch(`/api/ai-notify`, props);
  }
};

const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const AiNotifyModal = () => {
  const [email, setEmail] = useState("");
  const [recordId, setRecordId] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const isLoggedInUser = !!userService.hasUserData() || false;
  const debouncedEmail = useDebounce(email, 500);

  const modalTitle = "We're currently optimizing this feature";
  const modalDescription = !isLoggedInUser
    ? "To receive notifications about the availability of this feature, enter your email\naddress and click the button below"
    : "To receive notifications about the availability of this\nfeature, please click the button below";

  const getUserEmail = async () => {
    return !isLoggedInUser ? email : (await userService.getUser())?.fields.EMAIL;
  };

  const initModal = async () => {
    await sendData({ CLICKED_GENERATE: true, IS_LOGGED_IN: isLoggedInUser })
      .then((response) => setRecordId(response.data.id))
      .catch((error) => console.error("[AiNotifyModal] Initial sendData error:", error));
  };

  useEffect(() => {
    initModal();
  }, []);

  useEffect(() => {
    const validation = validateEmail(debouncedEmail);
    setIsValidEmail(debouncedEmail.length === 0 || validation);
  }, [debouncedEmail]);

  const handleCancel = () => {
    modalMachineInstance.send("CLOSE");
  };

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateNotifyForm = (): boolean => {
    if (!isLoggedInUser && (!email || !validateEmail(email))) {
      return false;
    }
    return true;
  };

  const submitNotification = async (): Promise<boolean> => {
    const updateData: SendDataProps = {
      ID: recordId!,
      CLICKED_GENERATE: true,
      IS_LOGGED_IN: isLoggedInUser,
      EMAIL: await getUserEmail(),
    };

    const res = await sendData(updateData);
    return res.status === 200 || res.status === 201;
  };

  const handleNotifySuccess = () => {
    if (!isLoggedInUser) {
      localStorage.setItem("aiNotifySubmitted", "true");
    }

    modalMachineInstance.send("CHANGE_VIEW", { viewId: "ai-notify-success-toast" });
  };

  const handleNotify = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isSubmitting || !validateNotifyForm()) return;

    setIsSubmitting(true);
    try {
      const success = await submitNotification();
      if (success) {
        handleNotifySuccess();
      } else {
        console.error("[AiNotifyModal] An error occurred while saving your data.");
      }
    } catch (error) {
      console.error("[AiNotifyModal] Update sendData error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isButtonDisabled = (): boolean => {
    if (isSubmitting) return true;
    if (!isLoggedInUser) {
      return !email || !validateEmail(email);
    }
    return false;
  };

  return (
    <div className="ai-notify-modal new-colors">
      <ModalHeader title={modalTitle} description={modalDescription} type="info" position="center" />
      <div className="ai-notify-modal__body">
        {!isLoggedInUser && (
          <div className="ai-notify-modal__input">
            <label className="ai-notify-modal__label">Email</label>
            <input
              className="ai-notify-modal__input-field"
              value={email}
              placeholder="Enter email address"
              onChange={handleEmail}
            />
            <span
              className={`ai-notify-modal__error-message ${
                isValidEmail ? "ai-notify-modal__error-message--hidden" : "ai-notify-modal__error-message--visible"
              }`}
            >
              {"Please enter a valid email address"}
            </span>
          </div>
        )}
        <Button type="secondary" iconEnd={<Checked />} onClick={handleNotify} disabled={isButtonDisabled()}>
          Notify me when available
        </Button>
      </div>
      <ModalFooter onCancel={handleCancel} />
    </div>
  );
};
